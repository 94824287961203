module.exports = {
  navMenu: {
    zy: '主页',
    zxyh: '最新优惠',
    srdk: '私人贷款',
    gywm: '关于我们',
    sqsrdk: '申请私人贷款',
    dkrx: '贷款热线',
    zhonggao: '忠告：借钱梗要还，咪俾钱中介！',
    cxjtsrx: '查询及投诉热线',
    fzrpzhm: '放债人牌照号码'
  },
  footer: {
    zxxx: '最新消息',
    njkrxz: '拟借款人须知',
    lxwm: '联系我们',
    tkjxz: '条款及细则',
    yszc: '私隐政策',
    sqdk: '申请贷款',
    bqsy: '利多喜有限公司版权所有',
    ljsq: '立即申请'
  },
  sixbox: {
    lht: '灵活兔',
    srdk: '私人贷款',
    jyzh: '结馀转户',
    lyjj: '一按楼宇接揭',
    yzdk: '业主贷款',
    scwj: '上传文件'
  },
  gafx: {
    title: '个案分享',
    desc: '了解我们不同类型服务以协助客户'
  },
  sqjk: '申请借款',
  zsga: '真实个案',
  promotion: {
    zxyh: '最新优惠',
    title: '最新优惠推广',
    desc: '掌握我们最新优惠'
  },
  policy: {
    title: '私隐政策',
    desc: '网页声明、收集个人资料声明及私隐政策',
    yuanze: {
      head: '就阁下向利多喜有限公司（“本公司”）提供的一切资料均会严加保密。为此，本公司贯彻以下私隐政策原则：',
      l1: '本公司只会收集我们认为相关而有助于瞭解阁下财务需要及以便本公司营运业务的个人资料。',
      l2: '本公司运用阁下的个人资料以便我们可以确认、验证及认证阁下的身份并向阁下提供更佳的客户服务和产品。',
      l3: '本公司可能将阁下的个人资料转交本公司集团其他成员或代理机构，但会依法进行。',
      l4: '除非已获阁下同意，本公司不会向任何外界机构透露阁下的个人资料。',
      l5: '本公司可能随时被要求向政府部门、司法机构或本公司的监管机构透露阁下的个人资料，然而本公司只会在适当权限下进行。',
      l6: '本公司致力保持准确而最新的阁下个人资料以作记录。',
      l7: '本公司实行严格的网上保安系统，可防止任何人未经授权而取得阁下的个人资料。'
    },
    sfzy: {
      sfzy: '阁下的私隐对本公司十分重要',
      ziliao: '本部份列出具体细节，说明本公司将如何处理阁下可能向本公司提供的个人资料',
      anquan: '资料的安全性',
      an1: '本公司一贯致力确保阁下的个人资料受安全保护，不会未经授权或意外地被他人取得、处理或清除。为致力保障资料安全，本公司在设施、电子系统及管理方面实施适当措施，以保障阁下的个人资料安全。',
      an2: '本公司网站的伺服器设有“防火牆”作保护，而本公司不断监察本身的系统，以防遭任何人士未经授权地进入。本公司不会以普通电邮向阁下传送个人资料。由于无法保证普通电邮的安全性，阁下只应利用本公司网站所提供的安全电邮设施向本公司发出电邮。',
      an3: '本公司将採取一切实际可行步骤，确保不会保留客户的个人资料超过必需的时间，而本公司亦会遵守香港特别行政区一切有关保存个人身份资料的法例及监管规定。',
      baozheng: '安全保证',
      bao1: '本公司提醒阁下不可将阁下的用户名称及/或密码及/或生物特徵数据与他人分享，尤须确保不会与未经授权人士共用或允许其使用此等资料。本公司会尽力维持高度安全标准，以保障阁下的利益。',
      bao2: '阁下的用户名称和密码乃阁下所独有，阁下应严守机密。切勿写下或让任何人得知此等资料。如阁下认为本身的用户密码和/或密码已向第三方洩露、遗失或被盗，又或阁下户口可能已被进行未经授权的交易，阁下有责任即时通知本公司。'
    },
    gytz: {
      head: '关于《个人资料（私隐）条例》（“条例”）政策指引及《个人信贷资料实务守则》致客户及其他个别人士的通知',
      l1: '尊重及保障个人资料之私隐权是利多喜有限公司“本公司”的一贯政策。遵守条例不单是我们管理层所奉行的宗旨，同时更是本公司每位职员的直接责任。本声明书清楚规定有关收集个人资料的目的及如何确保个人资料不被外洩。',
      l2: '本公司将小心保管客户所提供的个人资料。于有需要时，客户有权查阅及更正有关他的个人资料。客户须注意下列各事项。',
      l3: '“资料当事人”一词，不论于本通知何处提及，包括以下为个人的类别：',
      l3_1: '本公司提供的信贷及相关贷款服务和产品的申请人或客户 / 用户及其被授权人；',
      l3_2: '基于对本公司负有的责任而出任担保人、保证人及提供抵押、担保或任何形式的支持的人士；',
      l3_3: '任何公司申请人或客户/用户的董事、股东、高级职员及经理；',
      l3_4: '及任何使用本公司的网站、手机应用程式、智能柜员机及其他由本公司提供或认可的电子方式及程序以使用本公司服务的用户；',
      l3_5: '及本公司的供应商、承建商、服务供应商及其他合约对手。'
    },
    wmyw: '为免疑问，“资料当事人”不包括任何法人团体。本通知的内容适用于所有资料当事人，并构成其与本公司不时订立或可能订立的任何贷款账户或与本公司的相关协议或安排的条款及条件及任何合约的一部份。若本通知与有关合约存在任何差异或分歧，就有关保护资料当事人的个人资料而言概以本通知为准。本通知并不限制资料当事人在条例下之权利。',
    wm1: '资料当事人在开立或延续账户、建立或延续本公司信贷或要求本公司提供信贷及相关服务和产品时(包括但不限于本公司之私人贷款、循环贷款、楼宇按揭及楼宇估价服务等)需不时向本公司提供有关的资料，以作为处理及审批该项申请。所涉及的资料包括但不限于：',
    wm1_1: '全名；',
    wm1_2: '身份证号码或旅游证件号码包括身份证及旅游证件影印本及嵌入其集成电路中的资料；',
    wm1_3: '出生日期；',
    wm1_4: '居住及/或通讯地址；',
    wm1_5: '电话/手提电话号码；',
    wm1_6: '电邮地址；',
    wm1_7: '生物特徵数据、包括但不限于面容影像及储存于具生物识别功能的身份及/或旅游证件中的生物特徵数据，不论是否以用户的电子设备或其他方法以生物特徵数据感应模组收集；',
    wm1_8: '薪金及收入；',
    wm1_9: '家居开支及受养人数目；',
    wm1_10: '及本公司认为有需要的其他或再进一步资料。',
    wm2: '若资料当事人未能向本公司提供该等资料，可能会导致本公司无法开立或延续账户或建立或延续本公司信贷或要求本公司提供信贷及相关服务和产品。',
    wm3: '资料当事人与本公司在延续正常业务运作中，本公司亦会收集资料当事人的资料，例如，亲身、以电话、网上、手机应用程式、智能柜员机(或以其他方式)申请贷款或延续贷款账户（包括审视、重新考虑、评估、检查、检视、审查、审计、分析、监控、遵守及确保遵守相关法律、规则及规定），包括经信贷资料服务机构及/或电子身份认证服务的承办商所获得的资料。',
    wm4: '资料当事人之资料的用途将视乎其与本公司的关係性质有所不同，其中包括以下用途：',
    wm4_1: '评估资料当事人作为信贷及相关贷款服务和产品的实际或准或持续申请人的优点和适合性，以及处理和批核其申请、续期及/或取消；',
    wm4_2: '提供服务和信贷便利给资料当事人之日常运作；',
    wm4_3: '在资料当事人申请信贷时进行的信贷调查，及每年进行一次或以上的定期或特别账户审查。该等账户的审查将协助本公司判断应否增加或减少资料当事人的信贷额或维持资料当事人的信贷额不变；',
    wm4_4: '编制及维持本公司的信贷评分模式；',
    wm4_5: '提供信用查询备考书；',
    wm4_6: '协助其他财务机构作信用检查及追讨债务；',
    wm4_7: '确保资料当事人维持可靠信用；',
    wm4_8: '设计为资料当事人使用的信贷及相关贷款服务和产品；',
    wm4_9: '为推广服务、产品及其他标的(详见下述第11段)；',
    wm4_10: '计算本公司与资料当事人之间的负债款额；',
    wm4_11: '向资料当事人及为资料当事人的责任提供抵押的人士追收欠款；',
    wm4_12: '为符合根据下述适用于本公司或其任何分行或期望本公司或其任何分行遵从的有关披露及使用资料之责任、规定或安排：',
    wm4_12_1: '在香港特别行政区境内或境外对其具约束力或适用于其的任何法律；',
    wm4_12_2: '在香港特别行政区境内或境外并由任何法定、监管、政府、税务、执法或其他机构，或由金融服务提供者之自律监管或行业的团体或组织所发出或提供之任何指引或指导；',
    wm4_12_3: '本公司或其任何分行因其金融、商业、营业或其他利益或活动处于或关连于相关本地或海外的法定、监管、政府、税务、执法或其他机构或金融服务提供者之自律监管或行业团体或组织之司法管辖区而须承担或获施加与本地或海外之法定、监管、政府、税务、执法或其他机构或金融服务提供者之自律监管或行业团体或组织之间的任何合约承诺或其他承诺；',
    wm4_13: '根据任何本集团计划下就遵从洗黑钱、恐怖份子资金筹集或其他非法活动之批准或防止或侦测而作出本集团内及/或本公司不同部门之间的资料及信息分享及/或任何其他使用资料及信息的任何责任、规定、政策、程序、措施或安排；为推广服务、产品及其他标的(详见下述第11段)；',
    wm4_14: '使本公司的实在或建议承让人，或本公司对资料当事人的权利的参与人或附属参与人评核意图成为转让，参与或附属参与的交易；',
    wm4_15: '与资料当事人或其他人士的资料作比较以进行信贷调查，资料核实或以其他方法产生或核实资料，不论有关比较是否为对该资料当事人採取不利的追讨行动；',
    wm4_16: '作为维持资料当事人的信贷记录或其他记录，不论资料当事人与本公司是否存在任何关係，以作现在或将来参考用；',
    wm4_17: '就着资料当事人所欠本公司的一切债项向承保人投保与资料当事人相关连的人寿保险以保障一旦资料当事人身故后本公司的利益，而本公司将作为该保单的拥有人及唯一与最终受益人，“资料”在本条的释义包括但不限于资料当事人的姓名、身份证明文件号码、贷款户口号码、贷款额及不时改变的欠款额，该保单的拥有权、利益、权利及得益均属本公司独有而并不构成资料当事人对本公司欠款的扺押品；',
    wm4_18: '确认、验证及认证资料当事人的身份；及',
    wm4_19: '进行贷款申请、服务及持续服务的审视；进行欺诈行为审视及调查；进行、筹备、促使内部及外部审计；对贷款申请、服务及持续服务行使信贷监控；处理针对本公司的申索及潜在申索；对本公司（包括本公司之不同部门）、本公司集团及承包商行使内部监控及资料管理；及',
    wm4_20: '一切与上述有联繫、有附带性或有关的用途。',
    wm5: '本公司会对其持有的资料当事人资料保密，但本公司可能会把该等资料提供及披露给下述各方作第7段列出的用途：',
    wm5_1: '任何代理人、审计员、承包人、或向本公司提供行政、一般支援、审计、资料管理、信贷监控、分析、产品审视、欺诈行为审视及调查、合规监管、电讯、电脑、付款或证券结算、电子身份认证服务或其他与本公司业务运作有关的服务的第三方服务供应商，不论其所在地；',
    wm5_2: '任何对本公司有保密责任的人士，包括本公司集团内已承诺保持该资料保密的公司及本公司不同部门之间；',
    wm5_3: '付款银行向出票人提供已付款支票的副本（而其中可能载有关于收款人的资料）；',
    wm5_4: '任何付款到资料当事人账户的人士；',
    wm5_5: '任何从资料当事人收取付款的人士、其收款银行及任何处理或办理该付款的中介人士；',
    wm5_6: '信贷资料服务机构；而在资料当事人欠账时，则可将该等资料提供给收数公司；',
    wm5_7: '本公司或其任何分行在根据对其本身或其任何分行具约束力或适用的法例规定下之责任或其他原因而必须向该人作出披露，或按照及为实施由任何法定、监管、政府、税务、执法或其他机构或金融服务提供者之自律监管或行业团体或组织所提供或发出的指引或指导需预期向该人作出披露，或根据与本地或海外之法定、监管、政府、税务、执法或其他机构或金融服务提供者之自律监管或行业团体或组织之间的任何合约承诺或其他承诺而向该人作出任何披露之任何人士，该等人士可能处于香港特别行政区境内或境外；',
    wm5_8: '本公司的任何实在或建议承让人或就本公司对资料当事人的权利的参与人或附属参与人或受让人；及',
    wm5_9: '收集的资料向下列人士披露：',
    wm5_9_1: '本集团之任何成员；',
    wm5_9_2: '第三方金融机构、承保人、信用卡公司、证券、商品及投资服务供应商；',
    wm5_9_3: '第三方奖赏、年资奖励、联名合作及优惠计划供应商；',
    wm5_9_4: '本公司及本集团之联名合作伙伴(有关服务和产品的申请表上会提供联名合作伙伴的名称(视属何情况而定))；',
    wm5_9_5: '慈善或非牟利组织；及',
    wm5_9_6: '就上述第7(i)段而获本公司任用之第三方服务供应商(包括但不限于代寄邮件公司、电讯公司、电话促销及直销代理人、电话服务中心、数据处理公司、资讯科技公司及电子身份认证服务的承办商)，不论其所在地。',
    bushi: {
      head: '本公司可能为上述第7段所列之目的不时将资料当事人的资料转移往香港特别行政区境外的地区。',
      l1: '就以上第7(c)、7(o) 及7(r)段之目的，本公司可不时向信贷资料服务机构查阅及提取资料当事人的个人信贷资料，以检讨任何与信贷安排相关的下述事项：',
      l1_1: '资料当事人的身份；',
      l1_2: '增加信贷额；',
      l1_3: '缩减信贷额(包括取消信贷或降低信贷额)；或',
      l1_4: '与资料当事人制订或推行债务安排计划。'
    },
    hezhunguiding: {
      head: '本公司向信贷资料服务机构查阅资料当事人的个人信贷资料时，必须符合根据该条例核准和发出之《个人信贷资料实务守则》的规定。',
      l1: '就2011年4月1日或以后有关资料当事人按揭申请之资料(不论作为借款人、按揭人或担保人及不论以资料当事人单名或与其他人士联名方式)，本公司(以其自身及/或代理人身份)可向信贷资料服务机构提供下述关于资料当事人的资料(包括任何但不限于下述资料中不时更新之任何资料)：',
      l1_1: '全名；',
      l1_2: '就每宗按揭的身份(即作为借款人、按揭人或担保人，以及不论以资料当事人单名或与其他人士联名方式)；',
      l1_3: '身份证号码或旅游证件号码；',
      l1_4: '出生日期；',
      l1_5: '通讯地址；',
      l1_6: '就每宗按揭的按揭账户号码；',
      l1_7: '就每宗按揭的信贷种类；',
      l1_8: '就每宗按揭的按揭账户状况(如生效、已结束、撇账(因破产命令除外)、因破产命令的撇账)；及',
      l1_9: '就每宗按揭的按揭账户结束日期(如适用)。'
    },
    zhijiecuxiao: {
      head: '信贷资料服务机构会使用上述由本公司提供的资料，统计资料当事人(分别以借款人、按揭人或担保人身份，及不论其以单名或与其他人士联名方式)不时与香港特别行政区内的信贷提供者持有之按揭宗数，于信贷资料库内让信贷提供者共用(惟受限于按条例核准及发出之《个人信贷资料实务守则》的规定)。',
      l1: '使用资料作直接促销',
      liHead: '本公司拟使用资料当事人的资料作直接促销及本公司须为此目的取得资料当事人同意(包括资料当事人不反对之表示)。因此，请注意以下：',
      l1_1: '本公司持有资料当事人的姓名、联络详情、产品及服务投资组合信息、交易模式及行径、财务背景及统计资料可不时被本公司用于直接促销；',
      l1_2: '以下服务类别可作推广：',
      l1_2_1: '信贷及相关服务和产品；',
      l1_2_2: '奖赏、年资奖励或优惠计划及相关服务和产品；',
      l1_2_3: '本公司的联名合作伙伴提供之服务和产品(有关服务和产品的申请表上会提供联名合作伙伴的名称(视属何情况而定) )；及',
      l1_2_4: '为慈善及或非牟利的目的之捐款及资助；',
      l1_3: '上述服务、产品及标的可由本公司及/或下述人士提供或(如涉及捐款及资助)募捐：',
      l1_3_1: '本集团之任何成员；',
      l1_3_2: '第三方金融机构、承保人、信用卡公司、证券、商品及投资服务供应商',
      l1_3_3: '第三方奖赏、年资奖励、联名合作及优惠计划供应商；',
      l1_3_4: '本公司及本集团之联名合作伙伴(有关服务和产品的申请表上会提供联名合作伙伴的名称(视属何情况而定))；及',
      l1_3_5: '慈善或非牟利组织；',
      l1_4: '除本公司推广上述服务、产品及标的外，本公司同时拟提供列明于上述第11(a)段之资料至上述第11(c)段的所有或其中任何人士，该等人士藉以用于推广上述服务、产品及标的，而本公司须为此目的取得资料当事人同意(其中包括资料当事人不反对之表示)；',
      l1_5: '本公司如以上第11(d)段所述将资料提供予其他人士可能会获得金钱或其他财产的回报。如本公司会因为提供资料予其他人士而获得任何金钱或其他财产的回报，本公司会于以上第11(d)段所述徵求资料当事人同意或不反对时如是通知资料当事人。'
    },
    buyuanyi: {
      head: '若资料当事人不愿意本公司使用或提供其资料予其他人士，藉以用于以上所述之直接促销，资料当事人可通知本公司以行使其不同意此安排的权利。',
      l1: '根据条例中的条款及根据条例核准和发出的《个人信贷资料实务守则》，任何资料当事人有权：',
      l1_1: '查核本公司是否持有他的资料及查阅该等资料；',
      l1_2: '要求本公司改正任何有关他的不准确的资料；',
      l1_3: '查明本公司对于资料的政策及惯例和获告知本公司持有的个人资料种类；',
      l1_4: '按要求获告知哪些是会例行向信贷资料服务机构或收数公司披露的个人资料类别，及获本公司提供进一步资料，以便向有关信贷资料服务机构或收数公司提出查阅和改正资料的要求；及',
      l1_5: '对于本公司向信贷资料服务机构提供的任何账户资料(为免生疑问，包括任何账户还款资料)，于悉数清偿欠款以终止账户时，指示本公司要求有关信贷资料服务机构从其资料库中删除该等账户资料，惟是项指示必须于账户终止后5年内发出，且该账户在紧接账户终止之前5年内，并无超过60天的拖欠还款纪录。账户还款资料包括最后一次到期的还款额、最后一次报告期间所作出的还款额(即紧接本公司向信贷资料服务机构提供最后一次账户资料前不超过31天的期间)、剩馀可用信贷额、或未偿还款额及欠款资料(即过期欠款额及逾期还款日数、清还过期欠款的日期及全数清还拖欠超过60天的欠账之日期(如有)。',
      l2: '在账户出现任何欠款的情况下，除非欠款金额在由出现拖欠日期起计60天届满前全数清还或撇账(因破产命令除外)，信贷资料服务机构所可由全数清还欠款金额之日期起计5年保留账户还款资料(请见上述第12(e)段的定义)。',
      l3: '当资料当事人因被颁布破产命令而导致账户中的任何金额被撇账，不论账户还款资料(请见上述第12(e)段的定义)是否显示存有任何超过60天的欠款，信贷资料服务机构可由全数清还欠款金额之日期起计5年或由资料当事人提供证据通知信贷资料服务机构其已获解除破产命令的日期起计5年保留账户还款资料(以较先出现者为准)。',
      l4: '根据条例的条款，本公司有权就处理任何查阅资料的要求收取合理费用。',
      l5: '任何关于查阅或改正资料，或索取关于资料政策及惯例或所持有的资料种类的要求，应向下列人士提出：'
    },
    ziliaobaozhang: {
      zhuren: '资料保障主任',
      gongsi: '利多喜有限公司',
      dizhi: '香港九龙尖沙咀广东道28号力宝太阳广场14楼1412室',
      dianhua: '电话：26380086 传真：2638 0618',
      l1: '本公司在考虑任何信贷申请时或不时进行信贷审查时，会从信贷资料服务机构取得关于资料当事人的信贷报告。如资料当事人希望索阅该信贷报告，本公司会向其提供有关信贷资料服务机构的详细联络资料。',
      l2: '如本通知中文版本与英文版本有任何歧义，则以英文版本为准。'
    }
  },
  cond: {
    title: '条款及细则',
    desc: '条款及条件',
    l1: '法律声明',
    l1_1: '使用任何服务、浏览网站及使用手机应用程式前，请先阅读以下条款及条件。',
    l1_2: '此文件是您与利多喜有限公司之间的具法律约束力的协议，其规定了使用网站和手机应用程式的条款及条件。假如您使用服务、浏览网站及使用手机应用程式或其任何部分，您将被视为已同意此等条款及条件。',
    l1_3: '假如您不接受此等条款及条件，请不要使用服务、浏览网站或使用手机应用程式或其任何部分。',
    l1_4: '假如您在此等条款及条件修订后继续使用服务、浏览网站及使用手机应用程式，您将被视为已同意经修订的条款及条件。',
    l2: '定义',
    l2_1: '「本公司」指利多喜有限公司。',
    l2_2: '「服务」指浏览网站（https://www.rbmoney.hk）及使用本公司透过网站及/或手机应用程式提供的服务。',
    l2_3: '「网站」指本公司拥有及营运或为本公司营运的网站，包括（https://www.rbmoney.hk）（包括电脑及手机版本）及本公司可能不时营运及维持的其他网站。',
    l2_4: '「手机应用程式」的智能手机应用程式，包括苹果iOS和谷歌Google Play版本，及本公司可能不时推出、营运及维持的其他版本。',
    l2_5: '「条款及条件」指此等条款及条件以及本公司的《私隐政策》和《个人资料收集声明》。',
    l2_6: '「用户」指通过使用电子设备包括但不限于电脑和手机（“电子设备”）浏览网站的访客及使用手机应用程式的用户；包括通过网站或手机应用程式与本公司订立或确认订立贷款协议的借款人。',
    l3: '产品及服务的提供',
    l3_1: '网站和手机应用程式提供一站式贷款服务，以及最新的物业价值信息，帮助用户管理帐户。用户可在网上应用程式商店（Google Play和Apple Store）搜寻“快兔钱”手机应用程式作免费下载。如果发现任何程式有可疑，请不要下载或登录，并请立即停止操作。',
    l3_2: '本公司仅将服务提供予本公司根据香港《放债人条例》可合法及适时地提供服务的司法管辖区，而网站和手机应用程式上的资料并不旨在提供予位于或居住在香港司法管辖区以外的地区的人士。浏览网站和手机应用程式的人士必须自行了解并遵守所有相关的法例及限制。',
    l3_3: '服务由本公司酌情提供，并受限于提供服务的个别条款及条件，本公司可随时撤回或修改服务，恕不另行通知。',
    l3_4: '任何用户透过网站及/或手机应用程式与本公司订立任何贷款协议均不可推翻地被视为在香港境内订立协议。本公司对于任何人适用特定服务的资格，具有最终决定权。',
    l4: '商标及版权',
    l4_1: '本公司拥有网站及/或手机应用程式的所有内容的版权，包括但不限于文字、图形、连结和声音的版权。未经本公司事先书面同意，此等内容不得被修改、转载、以任何可被存取的方式储存、传输（以任何形式或以任何方式）、複制、分发、用于创作衍生作品或以任何其他方式用于任何目的。',
    l4_2: '网站及/或手机应用程式所载的内容及信息，以及就网站及/或手机应用程式的使用而交付给用户的内容及信息，均属本公司和任何其他第三方（如适用）的财产。在网站及/或手机应用程式使用和显示的商标、商品名称和标志（“商标”），包括本公司和其他第三方的注册和未注册商标。网站及/或手机应用程式中的任何内容均不应被解释为授予使用当中显示的任何商标的任何许可或权利。本公司保留网站及/或手机应用程式的所有专有权利。',
    l4_3: '除非受适用法律限制，否则就用户使用网站及/或手机应用程式向本公司提交的任何信息或材料，用户特此授予本公司此类信息或材料当中的版权和知识产权的免专利权费永久许可，让本公司将之使用于其认为及暗示的任何目的，包括但不限于複制、传输、分发和发佈此类信息或材料。',
    l4_4: '用户同意，除非用户与本公司在双方另行直接订立的合约中另有约定，或法律另有要求，否则本公司无需对用户使用网站及/或手机应用程式时向本公司提交的任何信息或材料，承担任何保密义务或责任。',
    l5: '保安警告',
    l5_1: '避免透过在公共区域提供或无密码设置的无线网络（即Wi-Fi），浏览网站或使用手机应用程式。尤其是在处理财务交易时，请使用设有配置和可靠的互联网连接。',
    l5_2: '并非正在使用任何无线网络功能（如Wi-Fi、蓝牙、近场通讯NFC）或支付程式时，请将其关闭。使用Wi-Fi时，选择加密网络，并删除任何不必要的Wi-Fi连接设定。',
    l5_3: '请勿将电子设备连接到任何怀疑受病毒感染的电脑；同时，本公司建议您在电子设备安装防毒软件和设置防火牆。为避免任何潜在保安漏洞，请勿在已被破解的iPhone或已被取得系统最高管理权限的Android手机或任何厂方系统设定已被以非正式方式修改的设备，使用手机应用程式。用户可以下载适当的安全应用程式或软件。本公司有权拒绝用户通过已被破解、已被取得系统最高管理权限或厂方系统设定已被以非正式方式编辑的电子设备浏览网站和手机应用程式。',
    l5_4: '本公司建议用户开啓电子设备的屏幕自动锁定功能。不要使用容易被猜到的个人资料、数字或字词作为密码，并避免使用与用户曾使用或浏览的其他网络服务相同的密码。不要共用用户名称或密码，也不要允许他人查阅或使用用户名称和密码。勿让电子设备无人看守。使用浏览器和手机应用程式后请将其关掉。为安全起见，网站及/或手机应用程式被閒置15分钟后，其某些功能可能被关闭，或帐户会被登出。',
    l5_5: '如果用户怀疑用户名称或密码已被披露给他人、被盗或有人进行了未经授权的交易，用户有责任立即通知本公司，否则用户仍须对所有交易承担责任。',
    l5_6: '请定期下载并安装手机应用程式及其他手机程式、操作系统和浏览器的更新和修复版本。',
    l5_7: '本公司藉着使用加密技术，尽力确保通过网站及手机应用程式传输的用户数据和文件的安全性。为保护私隐和资产，用户不应向任何人透露其帐号、用户名称和密码，并应避免共用电子设备。此外，本公司提醒用户不要通过载于电邮中的超连结浏览网站或手机应用程式。本公司绝不会通过电邮要求用户提供帐号、密码或任何个人资料。',
    l5_8: '使用网站及手机应用程式可能涉及风险，包括导致用户的数据或电子设备（包括其设定）损失或损毁、在传输指示或讯息时出现拦截、干扰或变体、由于某些电子设备的自动密码保存功能而导致的未经授权使用密码。本公司对任何此类风险概不负责。',
    l6: '使用及披露个人资料',
    l6_1: '除非适用法律有所限制，否则用户同意，由本公司透过网站及/或手机应用程式不时收集的任何及所有用户个人资料/数据，可按照本公司的《私隐政策》及《个人资料收集声明》(副本请浏览https://www.rbmoney.hk)，为了当中所载的目的使用及披露予当中所载的人士。',
    l6_2: '当用户到访网站时，本公司会作纪录，以分析网站的到访人数及一般使用模式。其中部份资料将透过“Cookies”形式收集。',
    l6_3: '“Cookies”档案是由网站伺服器传送至浏览器的小段资讯，这些资讯储存于电脑硬碟中，使网站伺服器能于稍后再从浏览器内读取，而当中并不会收集可识别用户身份的资料。这有助网站保存某些有关用户使用本网站的资料，好让本公司能提供更有用的使用特色予用户、将本网站的内容制定得更切合用户的兴趣，以及（如适用）根据用户的使用模式向用户提供宣传内容及直接促销。本公司可从“Cookies”档案取得用户如何使用本网站的资料。',
    l6_4: '“Cookies”档案被设计成只可让发出的网站读取，但不能用作取得用户的硬碟资料、电邮地址或收集用户的敏感资料。',
    l6_5: '本公司亦与第三方机构合作，为网站的一般使用及活动进行研究，此等第三方机构包括谷歌、雅虎、Facebook及DoubleClick，他们使用包括“Cookies”、“Spotlight”及“Web Beacon (网络信标)”等的监察技术来进行研究，透过此等技术，他们将所收集得到的资料 (i) 用以得到网站用户的资料，包括其人口统计、行为及使用模式；(ii) 作出更准确的汇报；及 (iii) 协助提升本公司的市场推广成效；他们将收集到的资料经过处理后与本公司分享，然而不论是谷歌、雅虎、Facebook或DoubleClick，进行上述研究的过程中均不会收集或与本公司分享任何可以识别用户身份的资料。',
    l6_6: '大部份的浏览器都预设为接纳使用“Cookies”档案。若用户认为有需要，用户可将浏览器的“Cookies”设定停止或设置为当进行设定时通知用户。倘若用户将其网络浏览器的“Cookies”档案设定为停止运作，便未必能使用本公司于网上提供的财务产品及服务。倘若用户接纳使用“Cookies”档案，即代表用户同意其资料如上文般被收集、贮存、读取及使用。',
    l6_7: '用户如欲得到更多有关使用及收集“Cookies”档案的资讯及选择退出有关程序，可探访以下网站：谷歌https://policies.google.com/technologies/cookies?hl=en-US、雅虎http://privacy.yahoo.com/privacy/us/pixels/details.html、Facebook https://www.facebook.com/legal/FB_Work_Cookies及 DoubleClick doubleclick.net。',
    l6_8: '如果用户的电子设备有SD卡或其他储存设备，本公司可能会使用这些储存设备来储存及存取加密了的应用数据，以便操作网站及/或手机应用程式。如用户不允许本公司以上述方式使用信息，用户可随时更改电子设备的设定或删除手机应用程式。在这种情况下，用户将无法使用网站及/或手机应用程式。',
    l7: '连结到其他网站的超连结',
    l7_1: '在某些情况下，本公司会透过网站及/或手机应用程式提供连结到其他位置或网站的超连结。这些超连结会连结到由与本公司a.无任何联繫或关係的第三方所发佈或营运的网站。在网站及/或手机应用程式中加入这些超连结，是为了促进用户使用体验，并仅供参考。本公司尽力选择信誉良好的网站及信息来源，以方便用户查阅相关信息。',
    l7_2: '本公司提供超连结，但不得因此而被视为同意、推荐、认可、保证或介绍任何第三方或该等第三方在其网站上提供的服务/产品，或与该等第三方和网站有任何形式的合作。',
    l7_3: '本公司并不以任何方式对超连结的内容负责。用户在使用这些超连结时须自己承担风险，本公司对由于用户使用超连结而发生或遭受或与之有关的任何损害或损失，概不负责。本公司并非用户与外部网站供应商之间订立的任何合约安排的当事方。本公司并无调查、核实、监察或认可这些资源所提供的内容、准确性、所表达的意见及所提供的其他连结。',
    l7_4: '连结到其他网站的超连结，倘若包含可下载的软件，其只为方便用户而提供。本公司并不对用户在下载软件时遇到的任何困难或下载的任何后果承担任何责任。使用从互联网下载的任何软件可能受特许协议所管限，而用户如不遵守上述特许协议的条款，可能会导致侵犯相关软件供应商的知识产权，对此本公司概不以任何方式负责。',
    l7_5: '当用户点击超连结及离开网站及/或手机应用程式时，用户须遵守其将要浏览的其他网站的使用条款及私隐政策。',
    l7_6: '本公司可能会在网站及/或手机应用程式提供同一集团公司的网站的超连结，以方便用户查阅。这些被连结之网站可能设有不同的使用条款及条件，因此您应在使用这些被连结之网站前，查询及细阅适用的条款及条件。',
    l8: '从外部网站连结到本网站的超连结',
    l8_1: '用户必须先徵求本公司的事先书面批准，才可以任何形式在第三方的网站建立连结到本公司网站的超连结。本公司具有绝对酌情决定是否批准建立超连结。在正常情况下，本公司只会批准清楚地显示本公司的名称或网站地址的超连结。除非在非常特殊的情况下，否则本公司不会批准第三方使用或展示我们的标志、商品名称和商标作为超连结，本公司具有绝对酌情决定为此徵收一定的费用。',
    l8_2: '对于从第三方网站连结到本公司网站的超连结的建立，本公司概不负责。如此建立的任何超连结不得构成本公司与该第三方网站的任何形式的合作或构成本公司认可该第三方网站。任何连结到本网站的超连结必须时刻是活跃而直接的超连结，且只能直接连结到本网站的首页，而不得“框架化”或“深层连结”本网站或其内容。',
    l8_3: '本公司不会为用户或任何第三方因为此等超连结而产生或遭受的任何损失或损害承担任何责任。本公司保留权利随时行使其絶对酌情权取消已授予的任何批准及/或要求删除任何上述连结，而无需另行通知或作出补偿。',
    l9: '不作保证',
    l9_1: '虽然本公司已极谨慎地准备网站及手机应用程式所载的信息和资料，但上述信息和资料是按“原样”提供给用户而本公司不作任何形式的明示或暗示的保证（包括但不限于可商售性、所有权、对某特定用途的适用性、不含电脑病毒、不侵犯第三方权益、兼容性、安全性、准确性和完整性），并可能在不作事先通知的情况下随时被更改及撤回。',
    l9_2: '网站及手机应用程式所载的信息和资料可能由本公司相信为可靠的第三方来源提供或获取。本公司并无核实也不保证上述信息和资料属准确、完整或中肯或作出有关陈述。经济、统计、财务及估值数据均以约数、概括或简化形式呈示，并可能在不作通知的情况下随时被更改。',
    l9_3: '用户应该了解，网站及手机应用程式所载的关于费率、指数、价值和物业价值的任何信息会有时间上的延迟。本公司将尽力确保但不保证所提供信息的准确性及可靠性，且不会为因为任何不准确或遗漏而引起之损失或损害，承担任何责任（不论是民事侵权行为责任、合约责任或其他责任）。',
    l9_4: '网站或手机应用程式所载的信息和资料不应被视为向任何人作出要约或徵求出售、认购或提供任何建议或意见。上述信息和资料并不旨在被用户依赖以作出任何决定。用户在作出任何财务决定或使用服务前，应谘询自己的专业顾问。',
    l10: '法律责任的限度及免责条款',
    l10_1: '在法律允许的最大范围内：',
    l10_1_1: '假如任何人直接或间接因为透过使用网站及/或手机应用程式的引用资料或超连结、书籤建议或以其他方式，使用或依赖与本公司无任何联繫或关係的任何第三方所拥有及/或营运的任何网站所载的任何信息或资料（包括但不限于物业估值的网页），而因此蒙受任何直接、间接、相应、专项或附带的损害、损失或开支，无论是否因疏忽造成，并在所有上述情况下，无论本公司是否已获告知发生损害、损失或开支的可能性，本公司也免除一切责任，本公司加入超连结并不意味着认可被连结之网站所载的任何信息或资料。',
    l10_1_2: '本公司概不承担网站、手机应用程式及超连结所载的信息和资料的任何错误、遗漏或不准确，也不负责因为以下原因而导致的任何损失或损害：任何一方使用或依赖或无法使用上述信息和资料，或操作的任何错误、中断、延迟或不完整的传输、传输过程中没有加密或加密失败（包括下载或上载数据）、传输未获接收或只部分接收、传输中断、传输延迟、线路或系统故障或电脑病毒，',
    l10_1_3: '本公司也不以任何方式负责由此产生的直接、间接、专项、附带或相应的损害。或下载或安装手机应用程式而带来的任何病毒、木马程式、蠕虫、软件炸弹或类似物品或程序所造成的网站及手机应用程式、其内容或关联服务的任何缺陷、错误、瑕疵、故障、过失、未能提供或不准确性，在每一种情况下，上述事件发生的情况是超出本公司的合理控制范围。',
    l10_1_4: '在任何情况下，即使本公司已被告知下述项目发生的可能性，本公司也不负责有关用户浏览网站或使用手机应用程式的性能、系统、伺服器或连线故障、错误、遗漏、中断，及安全漏洞、病毒、恶意代码、乱码或数据错误、操作或传输延迟、传输未获接收或只部分接收或传输错误。',
    l10_2: '用户须自行承担使用服务的一切风险。本公司并不保证没有病毒或其他具破坏性的属性会传输到用户的电子设备或用户的电子设备不会受损或作出有关陈述。用户须自行负责设立足够的保护，并备份数据及/或设备，并採取合理和适当的预防措施以扫描病毒或其他具破坏性的属性，以预防、保障及确保没有病毒进入用户的电子设备。本公司并不保证可能与服务共同使用或因为使用服务而可能使用的任何第三方软件的准确性、功能或性能或作出有关陈述。',
    l10_3: '使用服务将同时须使用短信及/或电邮。使用服务、短信及/或电邮将涉及使用互联网数据，用户须自行承担任何互联网数据费用，包括所有跨网、漫游及/或数据漫游费用。持续让全球定位系统在背景使用，会大幅减少电池寿命。',
    l11: '弥偿',
    l11_1: '用户特此同意作出弥偿，并使本公司获充分并有效地弥偿本公司因为或直接或间接涉及以下事项而遭受或产生的任何诉讼、法律责任、费用、索赔、损失、损害、法律程序或开支（包括在完全弥偿基准上的法律费用、讼费及开支）：',
    l11_1_1: '用户浏览及/或使用网站及/或手机应用程式，及/或任何其他人士或个体使用服务或网站及/或手机应用程式，而该人士或个体能够使用用户的用户名称及/或密码浏览服务或网站及/或手机应用程式；或',
    l11_1_2: '用户或任何其他人士或个体违反或不遵守任何此等条款及条件，而该人士或个体能够使用用户的用户名称及/或密码浏览服务或网站及/或手机应用程式。',
    l12: '修改',
    l12_1: '本公司保留其酌情决定权，在没有事先警告或通知的情况下，更改网站及/或手机应用程式中包含的任何信息或资料，以及网站及/或手机应用程式的使用条款及条件。',
    l13: '终止',
    l13_1: '本公司可随时在有因由或无因由的情况下，终止用户浏览网站及/或手机应用程式，而不一定须给予用户通知。',
    l14: '语言',
    l14_1: '此等条款及条件的中文版本仅供参考。如中英文版本有衝突或歧义，概以英文版本为准。',
    l15: '其他',
    l15_1: '用户承认，此等条款及条件不得限制在网站及/或手机应用程式或透过网站及/或手机应用程式提供的任何特定产品和服务的个别条款及条件所列明的任何具体规定。',
    l15_2: '此等条款及条件是附加于本公司与用户之间订立的贷款协议中的条款。',
    l16: '司法管辖区及法律选择',
    l16_1: '本公司在香港经营及维持服务。即使网站及手机应用程式可在香港以外地区浏览或使用，但透过网站及手机应用程式提供的服务及根据网站及手机应用程式订立的所有合同，均属在香港境内提供并订立及须不可推翻地被视为在香港境内提供并订立。本公司是在香港及被视为在香港营运网站及手机应用程式，而网站及手机应用程式的营运受香港法律管辖。',
    l16_2: '本公司并不对网站及手机应用程式所载的信息和资料是否适当或获允许在香港境外的司法管辖区使用作出陈述。此等条款及条件受香港法律管辖，而用户同意任何争议须受香港法院的非专属司法管辖权管辖。',
    l17: '小心伪冒员工推销贷款产品',
    l17_1: '本公司不时接到投诉，指收到自称是本公司的员工的人士的来电，推销贷款服务。如果客户及/或公众不接受推销，他们会恶言相向。本公司的政策是：',
    l17_1_1: '所有员工均接受过有关客户服务和产品知识方面的专业培训，并将有礼并诚恳地服务客户和准客户；',
    l17_1_2: '如果准客户未具资格享用本公司的贷款服务或集团公司内的服务，员工只会在客户同意的情况下将该等客户转介至与本公司有关、关联或附属的其他公司；',
    l17_1_3: '本公司保留对伪冒本公司或其附属或关联公司的员工的人士採取法律行动的权利；',
    l17_1_4: '所有推销来电均直接由本公司的客户服务主任、其分行或直销部门的销售代表或透过授权经销商（统称为“销售代表”）作出，而来电也会显示电话号码；',
    l17_1_5: '所有销售代表均应有自己的员工编号。他们乐意披露员工编号、分行名称、部门名称或授权经销商的名称及其联繫电话号码。',
    l17_2: '如果客户或准客户收到任何上述涉嫌伪冒来电或有任何怀疑，请致电本公司的客户服务热线：26380086。'
  },
  borrow: {
    title: '拟借款人须知'
  },
  news: {
    title: '最新消息',
    desc: '留意我们最新公布'
  },
  about: {
    lht: '灵活兔',
    zyhs: '自由供款，自由话事',
    znlhzz: '助您灵活周转',
    wxjslxzc: '我想减少利息支出',
    tksq: '特快申请',
    ybxx: '填写个人资料',
    dkzl: '联络资料',
    gzzx: '职业资料',
    name: '姓名（香港身份证姓名）',
    phone: '电话号码',
    email: '电邮',
    agree: '本人同意接收 Rabbit Money 的最新消息、优惠及推广活动，并已详细阅读及明白下列的',
    grzlsytl: '个人资料私隐条例',
    applicating: '人正在申请中',
    fzrpzhm: '放债人牌照号码',
    next: '下一步',
    pre: '上一步',
    submit: '递交申请',
    why: '我们有各种类型贷款服务',
    desc: '为阁下提供最适切所需,只需简单3个步骤',
    fourBox: {
      lht: {
        title: '灵活兔',
        desc: '自由供款，自由活事，助您灵活週转'
      },
      srdk: {
        title: '私人贷款',
        desc: '定息定额，特快现金到手'
      },
      jyzh: {
        title: '结馀转户',
        desc: '大额清数，减轻压力，共渡难关'
      },
      yalyjj: {
        title: '一按楼宇接揭',
        desc: '特高成数，文件简易'
      },
      yzdk: {
        title: '业主贷款',
        desc: '专人处理业主贷款方案，贷款额特高！'
      },
      scwj: {
        title: '上传文件',
        desc: '简单上传申请文件'
      }
    },
    purpose: [
      '交税',
      '旅游',
      '每月开支',
      '投资',
      '生意周转',
      '医疗',
      '家具装修',
      '结婚',
      '进修'
    ],
    area: [
      '新界',
      '九龙',
      '香港'
    ],
    region: {
      '新界': [
        '离岛',
        '葵青',
        '北区',
        '西贡',
        '沙田',
        '大埔',
        '荃湾',
        '屯门',
        '元朗'
      ],
      '九龙': [
        '九龙城',
        '观塘',
        '深水埗',
        '黃大仙',
        '油尖旺'
      ],
      '香港': [
        '中西区',
        '東区',
        '湾仔',
        '南区'
      ]
    },
    working: [
      '全职',
      '兼职',
      '待业',
      '自雇'
    ],
    situation: [
      '与父母同住',
      '自置（有按揭）',
      '公共房屋',
      '套房',
      '租用',
      '自置（无按揭）',
      '其它'
    ],
    occupation: [
      '会计业',
      '广告业/市场推广',
      '航空业',
      '美容行业/健身中心',
      '专业服务/顾问',
      '建造业',
      '设计业',
      '教育业',
      '机械工程业',
      '娱乐/传媒',
      '金融业',
      '餐饮业',
      '政府部门',
      '酒店业/旅游业',
      '通信业/资讯科技业',
      '法律服务',
      '人力资源顾问',
      '制造业',
      '医疗服务',
      '楼宇/物业代理',
      '零售业',
      '船务业/贸易',
      '运输业/物流业',
      '公司拥有人/创办人'
    ],
    contact: [
      '电话',
      'whatsapp'
    ],
    contactTime: [
      '尽快',
      '9am-12pm',
      '12pm-3pm',
      '3pm-6pm'
    ],
    messageRoute: [
      'Facebook/Instagram',
      '网上广告',
      '关键字搜索',
      '电视广告',
      'Rabbit Money官网',
      'X Wallet手机应用程式',
      'YouTube',
      '宣传单张',
      '手机短信推广',
      '电邮推广',
      '户外广告',
      '亲友推介'
    ],
    ge: '个',
    qtx: '请填写',
    qxz: '请选择',
    qsr: '请输入',
    qsc: '请上传',
    qlcxm: '请列出其姓名',
    qxxydjmbxl: '请详细阅读及明白下列',
    jzzl: '居住资料',
    szspwjhtgcy: '上载审批文件或跳过此页',
    scndwjjkjkspsd: '上传你的文件即可加快审批速度。',
    nyldx: '您与利多喜有限公司的关係',
    nsf: '您是否为利多喜有限公司、其附属公司或并联属公司不论在香港境内或境外，或利多喜有限公司能对其行使控制的其他实体的董事／僱员／控权人／小股东控权人或上述人仕之亲属？',
    tzbgs: '如您与利多喜有限公司的关系有所改变或不再正确，请尽快以书面形式通知本公司',
    purposeLabel: '贷款目的',
    priceLabel: '贷款金额',
    cardNumberLabel: '香港身份证号码',
    areaLabel: '区域',
    regionLabel: '地区',
    situationLabel: '住宅状况',
    workingLabel: '现时工作状况',
    occupationLabel: '职业',
    identityLabel: '香港身份证',
    addressLabel: '最近2个月内居住地址证明 （银行月结单或公共机构发出有效的帐单并附上阁下姓名）',
    recordLabel: '最近2个月之入息证明（如个人或公司银行户口纪录 / 僱主薪金证明信 / 税单）',
    residenceLabel: '有关物业按揭的供款证明及结欠证明',
    contactLabel: '联络方法',
    contactTimeLabel: '联络时间',
    messageRouteLabel: '从什麽途径得知Rabbit Money Money',
    tujingLabel: '途径',
    brdsfrs: '本人同意授权贵公司向任何第三方人士索取有关本人的资料，包括但不限于向信贷资料服务机构/环联资讯有限公司索取本人之信贷报告，用作是次及日后审核或覆核用途'
  },
  guanyuwomen: {
    title: '联络我们',
    desc: '联络我们',
    l1: '利多喜有限公司',
    l2: '香港九龙尖沙咀广东道28号力宝太阳广场14楼1412室',
    l3: '电话：26380086 '
  },
  result: {
    thanks: '己成功提交申请',
    gongxi: '多谢!我们已收到你的申请并处理中',
    jinkuai: '我们将会优先处理你的申请，客户服务专员会尽快联络你，按你财务状况，为你提供最合适你的贷款方案。如需进一步资料我们会尽快联络通知阁下，感谢!',
    duoxie: '多谢你选择Rabbit Money'
  }
}
