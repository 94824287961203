<template>
  <a-layout>
    <a-layout-header>
      <div class="navLeft">
        <router-link to="/index" @click.native="selectedKeys.default = ['/index']">
          <div class="logo">
            <img src="@/assets/images/LOGO.png">
          </div>
        </router-link>
        <a-menu
          v-model="selectedKeys.default"
          mode="horizontal"
          :style="{ lineHeight: '64px' }"
        >
          <a-menu-item key="/index">
            <router-link to="/index">
              {{ $t('navMenu.zy') }}
            </router-link>
          </a-menu-item>
          <a-menu-item key="/promotion">
            <router-link to="/promotion">
              {{ $t('navMenu.zxyh') }}
            </router-link>
          </a-menu-item>
          <a-menu-item key="/apply">
            <router-link to="/apply">
              {{ $t('navMenu.srdk') }}
            </router-link>
          </a-menu-item>
          <a-menu-item key="/about">
            <router-link to="/about">
              {{ $t('navMenu.gywm') }}
            </router-link>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="navRight">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            {{ language.default }} <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="items in language.arr"
              :key="items.value"
              :disabled="items.title === language.default"
              @click="changeLan(items)"
            >
              {{ items.title }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button type="default" @click="goApply">{{ $t('navMenu.sqsrdk') }}</a-button>
        <a-button type="primary"> <img src="@/assets/images/hotPhone.png" style="width: .9em; height: .9em; margin-right: 6px"> {{ $t('navMenu.dkrx') }}：852-26381800</a-button>
        <a-button type="default" @click="goWhatsApp()">Whatspp : 91500888</a-button>
        <div class="hambuger" @click="visible = true" />
        <!-- 手机端 -->
        <a-drawer
          class="homeDrawer"
          placement="right"
          :closable="false"
          :visible="visible"
          @close="onClose"
        >
          <a-menu
            v-model="selectedKeys.default"
            :style="{ lineHeight: '64px' }"
          >
            <a-menu-item key="/index">
              <router-link to="/index">
                {{ $t('navMenu.zy') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="/promotion">
              <router-link to="/promotion">
                {{ $t('navMenu.zxyh') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="/apply">
              <router-link to="/apply">
                {{ $t('navMenu.srdk') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="/about">
              <router-link to="/about">
                {{ $t('navMenu.gywm') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="daikuan" @click="goApply">{{ $t('navMenu.sqsrdk') }}</a-menu-item>
            <a-menu-item key="whatsApp" @click="goWhatsApp()">Whatspp : 91500888</a-menu-item>
            <a-menu-item key="rexian">{{ $t('navMenu.dkrx') }}：852-26381800
            </a-menu-item>
          </a-menu>
        </a-drawer>
      </div>
    </a-layout-header>
    <a-layout-content>
      <router-view />
    </a-layout-content>
    <a-layout-footer>
      <div class="noticeBox">
        <div class="noticeHead">
          <div class="zhonggao">{{ $t('navMenu.zhonggao') }}</div>
          <div class="phoneBox">
            <span>{{ $t('navMenu.cxjtsrx') }}：2638 1800</span>
            <span>{{ $t('navMenu.fzrpzhm') }}：2113/2022</span>
          </div>
        </div>
        <div class="noticeMsg">
          <div class="store">
            <img src="@/assets/images/APPS-LOGO.png">
            <!-- <div class="download">
              <img src="@/assets/images/appStore.png">
              <img src="@/assets/images/googlePay.png">
            </div> -->
          </div>
          <div class="tabs">
            <a-button type="link" @click="$router.push('/news')">{{ $t('footer.zxxx') }}</a-button>
            <a-button type="link" @click="$router.push('/borrow')">{{ $t('footer.njkrxz') }}</a-button>
            <a-button type="link" @click="$router.push('/tellus')">{{ $t('footer.lxwm') }}</a-button>
            <a-button type="link" @click="$router.push('/conditions')">{{ $t('footer.tkjxz') }}</a-button>
            <a-button type="link" @click="$router.push('/policy')">{{ $t('footer.yszc') }}</a-button>
            <a-button type="link" @click="$router.push('/apply')">{{ $t('footer.sqdk') }}</a-button>
          </div>
          <div class="social">
            <div class="sTitle">Social Media</div>
            <div class="iconBox">
              <a href="https://www.facebook.com/rabbitmoney" target="_blank">
                <img src="@/assets/images/facebook.png">
              </a>
              <a href="https://www.instagram.com/rabbitmoney_hk/" target="_blank">
                <img src="@/assets/images/ins.png">
              </a>
              <a href="https://wa.me/91500888">
                <img src="@/assets/images/talkline.png">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="copyRight">
        <span> CopyRights © 2022 All Rights Reserved by Lee Dor Cash Limited.</span>
        <span>{{ $t('footer.bqsy') }}</span>
        <span class="footerDisplay" @click="$router.push('/apply')">{{ $t('footer.ljsq') }}</span>
      </div>
    </a-layout-footer>
    <div v-show="modalVisible" class="noticeImportangBox">
      <div class="nhead">
        <span>重要通知</span>
        <a-icon @click="modalVisible = false" type="close" />
      </div>
      <div class="ncontent">
        <a-collapse accordion class="noticeCollapse">
          <a-collapse-panel
            v-for="items in noticeList"
            :key="items.noticeId"
            :header="items.noticeTitle"
          >
            <div v-html="items.noticeContent" />
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </a-layout>
</template>

<script>
import { getNotice } from '../utils/request'
export default {
  name: 'Home',
  data() {
    return {
      selectedKeys: {
        default: ['/index']
      },
      visible: false,
      language: {
        default: '繁體中文',
        arr: [{
          title: '繁體中文',
          value: 'tc'
        }, {
          title: '简体中文',
          value: 'cn'
        }],
        map: {
          'tc': '繁體中文',
          'cn': '简体中文'
        }
      },
      modalVisible: false,
      noticeList: []
    }
  },
  watch: {
    '$route.path'(newValue, oladValue) {
      this.selectedKeys.default = [newValue]
      this.visible = false
      if (newValue === '/index') {
        this.getNoticeList()
      }
    }
  },
  mounted() {
    this.selectedKeys.default = [this.$route.path]
    this.getNoticeList()
    this.language.default = this.language.map[localStorage.getItem('local') || 'tc']
  },
  methods: {
    onClose() {
      this.visible = false
    },
    changeLan(PARAM) {
      localStorage.setItem('local', PARAM.value)
      window.location.reload()
    },
    goApply() {
      this.$router.push('/apply')
    },
    goWhatsApp() {
      window.open('https://wa.me/91500888')
    },
    async getNoticeList() {
      const RESULT = await getNotice()
      if (RESULT.head.success) {
        this.noticeList = RESULT.body
        this.modalVisible = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.logo {
  float: left;
  width: 140px;
  height: 40px;
  margin: 3px 24px 16px 0;
}
.navLeft {
  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
    border: none !important;
    font-weight: bold;
    &.ant-menu-item-selected::after, &.ant-menu-item-active::after{
      width: 62%;
      height: 3px;
      background: #000B8C;
      content: '';
      position: absolute;
      bottom: 14px;
      left: 50%;
      right: -50%;
      transform: translateX(-50%);
    }
  }
}
.navRight {
  display: flex;
  align-items: center;
  button{
    margin: 0 10px;
  }
  .ant-dropdown-link{
    margin-right: 16px;
    color: #000000;
  }
  .hambuger{
    width: 1.2em;
    height: 1.2em;
    cursor: pointer;
    margin-top: 3px;
    background: url("../assets/images/hambuger.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: none;
  }
}
.noticeBox{
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
  background: #F5F9FD;
  .noticeHead{
    line-height: 54px;
    .zhonggao{
      font-size: 32px;
      font-weight: 500;
      color: #10CFC9;
    }
    .phoneBox{
      width: 36%;
      display: inline-flex;
      justify-content: space-between;
      font-size: 20px;
      color: #A0A0A0;
    }
  }
  .noticeMsg{
    width: 64%;
    display: inline-flex;
    margin-top: 3rem;
    justify-content: space-between;
    align-items: center;
    .store{
      display: flex;
      justify-content: space-between;
      >img{
        width: 100px;
        height: 100px;
      }
      .download{
        display: flex;
        width: 172px;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: flex-end;
        img{
          width: 148px;
          height: 45px;
          object-fit: contain;
        }
      }
    }
  }
  .tabs{
    display: flex;
    flex-wrap: wrap;
    margin-left: -20rem;
    button{
      width: 30%;
      color: #000000;
      &:hover{
        opacity: .7;
      }
    }
  }
  .social{
    align-self: flex-end;
    .sTitle{
      font-weight: bold;
      color: #000000;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .iconBox{
      display: flex;
      justify-content: flex-end;
      a{
        margin: 0 4px;
      }
      img{
        width: 1.3em;
        height: 1.3em;
        &:last-child{
          margin: 0;
        }
      }
    }
  }
}
.copyRight{
  display: flex;
  justify-content: space-between;
  background: #000B8C;
  color: #fff;
  padding: 2rem 11rem;
  .footerDisplay{
    display: none;
  }
}
@media screen and (max-width: 1680px) {
  .noticeBox{
    padding-top: 4rem;
    padding-bottom: 4rem;
    .noticeHead{
      .phoneBox{
        width: 55%;
      }
    }
    .tabs{
      margin-left: -8rem;
    }
  }
}
@media screen and (max-width: 1280px) {
  .noticeBox{
    .noticeMsg{
      width: 80%;
    }
  }
}
@media screen and (max-width: 1180px) {
  .navLeft {
    .ant-menu-horizontal{
      display: none;
    }
  }
  .navRight {
    button{
      display: none;
    }
    .hambuger{
      display: block;
    }
  }
  .copyRight{
    padding: 2rem 4rem;
  }
}
@media screen and (max-width: 992px) {
  .noticeBox{
    padding-top: 2rem;
    padding-bottom: 2rem;
    .noticeHead{
      .phoneBox{
        width: 82%;
      }
    }
    .noticeMsg{
      .store{
        display: none;
      }
    }
    .tabs{
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .copyRight{
    justify-content: center;
    font-size: 26px;
    >span{
      display: none;
    }
    .footerDisplay{
      display: block;
    }
  }
}
@media screen and (max-width: 576px) {
  .noticeBox{
    .noticeMsg{
      width: 92%;
      margin-top: 2rem;
      flex-wrap: wrap;
      >div{
        width: 100%;
      }
      .tabs{
        order: 2;
        button{
          width: 33%;
        }
      }
      .social{
        order: 1;
        .sTitle{
          display: none;
        }
        .iconBox{
          padding: 0 2rem;
          margin-bottom: 12px;
        }
      }
    }
    .noticeHead{
      .zhonggao{
        font-size: 24px;
      }
      .phoneBox{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        >span{
          width: 100%;
        }
      }
    }
  }
}
</style>
