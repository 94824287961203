module.exports = {
  navMenu: {
    zy: '主頁',
    zxyh: '最新優惠',
    srdk: '私人貸款',
    gywm: '關於我們',
    sqsrdk: '申請私人貸款',
    dkrx: '貸款熱線',
    zhonggao: '忠告：借錢梗要還，咪俾錢中介！',
    cxjtsrx: '查詢及投訴熱線',
    fzrpzhm: '放債人牌照號碼'
  },
  footer: {
    zxxx: '最新消息',
    njkrxz: '擬借款人須知',
    lxwm: '聯繫我們',
    tkjxz: '條款及細則',
    yszc: '私隱政策',
    sqdk: '申請貸款',
    bqsy: '利多喜有限公司版權所有',
    ljsq: '立即申請'
  },
  sixbox: {
    lht: '靈活兔',
    srdk: '私人貸款',
    jyzh: '結餘轉戶',
    lyjj: '一按樓宇接揭',
    yzdk: '業主貸款',
    scwj: '上傳文件'
  },
  gafx: {
    title: '個案分享',
    desc: '了解我們不同類型服務以協助客戶'
  },
  sqjk: '申請借款',
  zsga: '真實個案',
  promotion: {
    zxyh: '最新優惠',
    title: '最新優惠推廣',
    desc: '掌握我們最新優惠'
  },
  policy: {
    title: '私隱政策',
    desc: '網頁聲明、收集個人資料聲明及私隱政策',
    yuanze: {
      head: '就閣下向利多喜有限公司（「本公司」）提供的一切資料均會嚴加保密。為此，本公司貫徹以下私隱政策原則：',
      l1: '本公司只會收集我們認為相關而有助於瞭解閣下財務需要及以便本公司營運業務的個人資料。',
      l2: '本公司運用閣下的個人資料以便我們可以確認、驗證及認證閣下的身份並向閣下提供更佳的客戶服務和產品。',
      l3: '本公司可能將閣下的個人資料轉交本公司集團其他成員或代理機構，但會依法進行。',
      l4: '除非已獲閣下同意，本公司不會向任何外界機構透露閣下的個人資料。',
      l5: '本公司可能隨時被要求向政府部門、司法機構或本公司的監管機構透露閣下的個人資料，然而本公司只會在適當權限下進行。',
      l6: '本公司致力保持準確而最新的閣下個人資料以作記錄。',
      l7: '本公司實行嚴格的網上保安系統，可防止任何人未經授權而取得閣下的個人資料。'
    },
    sfzy: {
      sfzy: '閣下的私隱對本公司十分重要',
      ziliao: '本部份列出具體細節，說明本公司將如何處理閣下可能向本公司提供的個人資料',
      anquan: '資料的安全性',
      an1: '本公司一貫致力確保閣下的個人資料受安全保護，不會未經授權或意外地被他人取得、處理或清除。為致力保障資料安全，本公司在設施、電子系統及管理方面實施適當措施，以保障閣下的個人資料安全。',
      an2: '本公司網站的伺服器設有「防火牆」作保護，而本公司不斷監察本身的系統，以防遭任何人士未經授權地進入。本公司不會以普通電郵向閣下傳送個人資料。由於無法保證普通電郵的安全性，閣下只應利用本公司網站所提供的安全電郵設施向本公司發出電郵。',
      an3: '本公司將採取一切實際可行步驟，確保不會保留客戶的個人資料超過必需的時間，而本公司亦會遵守香港特別行政區一切有關保存個人身份資料的法例及監管規定。',
      baozheng: '安全保證',
      bao1: '本公司提醒閣下不可將閣下的用戶名稱及/或密碼及/或生物特徵數據與他人分享，尤須確保不會與未經授權人士共用或允許其使用此等資料。本公司會盡力維持高度安全標準，以保障閣下的利益。',
      bao2: '閣下的用戶名稱和密碼乃閣下所獨有，閣下應嚴守機密。切勿寫下或讓任何人得知此等資料。如閣下認為本身的用戶密碼和/或密碼已向第三方洩露、遺失或被盜，又或閣下戶口可能已被進行未經授權的交易，閣下有責任即時通知本公司。'
    },
    gytz: {
      head: '關於《個人資料（私隱）條例》（「條例」）政策指引及《個人信貸資料實務守則》致客戶及其他個別人士的通知',
      l1: '尊重及保障個人資料之私隱權是利多喜有限公司「本公司」的一貫政策。遵守條例不單是我們管理層所奉行的宗旨，同時更是本公司每位職員的直接責任。本聲明書清楚規定有關收集個人資料的目的及如何確保個人資料不被外洩。',
      l2: '本公司將小心保管客戶所提供的個人資料。於有需要時，客戶有權查閱及更正有關他的個人資料。客戶須注意下列各事項。',
      l3: '「資料當事人」一詞，不論於本通知何處提及，包括以下為個人的類別：',
      l3_1: '本公司提供的信貸及相關貸款服務和產品的申請人或客戶 / 用戶及其被授權人；',
      l3_2: '基於對本公司負有的責任而出任擔保人、保證人及提供抵押、擔保或任何形式的支持的人士；',
      l3_3: '任何公司申請人或客戶/用戶的董事、股東、高級職員及經理；',
      l3_4: '及任何使用本公司的網站、手機應用程式、智能櫃員機及其他由本公司提供或認可的電子方式及程序以使用本公司服務的用戶；',
      l3_5: '及本公司的供應商、承建商、服務供應商及其他合約對手。'
    },
    wmyw: '為免疑問，「資料當事人」不包括任何法人團體。本通知的內容適用於所有資料當事人，並構成其與本公司不時訂立或可能訂立的任何貸款賬戶或與本公司的相關協議或安排的條款及條件及任何合約的一部份。若本通知與有關合約存在任何差異或分歧，就有關保護資料當事人的個人資料而言概以本通知為準。本通知並不限制資料當事人在條例下之權利。',
    wm1: '資料當事人在開立或延續賬戶、建立或延續本公司信貸或要求本公司提供信貸及相關服務和產品時(包括但不限於本公司之私人貸款、循環貸款、樓宇按揭及樓宇估價服務等)需不時向本公司提供有關的資料，以作為處理及審批該項申請。所涉及的資料包括但不限於：',
    wm1_1: '全名；',
    wm1_2: '身份證號碼或旅遊證件號碼包括身份證及旅遊證件影印本及嵌入其集成電路中的資料；',
    wm1_3: '出生日期；',
    wm1_4: '居住及/或通訊地址；',
    wm1_5: '電話/手提電話號碼；',
    wm1_6: '電郵地址；',
    wm1_7: '生物特徵數據、包括但不限於面容影像及儲存於具生物識別功能的身份及/或旅遊證件中的生物特徵數據，不論是否以用戶的電子設備或其他方法以生物特徵數據感應模組收集；',
    wm1_8: '薪金及收入；',
    wm1_9: '家居開支及受養人數目；',
    wm1_10: '及本公司認為有需要的其他或再進一步資料。',
    wm2: '若資料當事人未能向本公司提供該等資料，可能會導致本公司無法開立或延續賬戶或建立或延續本公司信貸或要求本公司提供信貸及相關服務和產品。',
    wm3: '資料當事人與本公司在延續正常業務運作中，本公司亦會收集資料當事人的資料，例如，親身、以電話、網上、手機應用程式、智能櫃員機(或以其他方式)申請貸款或延續貸款賬戶（包括審視、重新考慮、評估、檢查、檢視、審查、審計、分析、監控、遵守及確保遵守相關法律、規則及規定），包括經信貸資料服務機構及/或電子身份認證服務的承辦商所獲得的資料。',
    wm4: '資料當事人之資料的用途將視乎其與本公司的關係性質有所不同，其中包括以下用途：',
    wm4_1: '評估資料當事人作為信貸及相關貸款服務和產品的實際或準或持續申請人的優點和適合性，以及處理和批核其申請、續期及/或取消；',
    wm4_2: '提供服務和信貸便利給資料當事人之日常運作；',
    wm4_3: '在資料當事人申請信貸時進行的信貸調查，及每年進行一次或以上的定期或特別賬戶審查。該等賬戶的審查將協助本公司判斷應否增加或減少資料當事人的信貸額或維持資料當事人的信貸額不變；',
    wm4_4: '編制及維持本公司的信貸評分模式；',
    wm4_5: '提供信用查詢備考書；',
    wm4_6: '協助其他財務機構作信用檢查及追討債務；',
    wm4_7: '確保資料當事人維持可靠信用；',
    wm4_8: '設計為資料當事人使用的信貸及相關貸款服務和產品；',
    wm4_9: '為推廣服務、產品及其他標的(詳見下述第11段)；',
    wm4_10: '計算本公司與資料當事人之間的負債款額；',
    wm4_11: '向資料當事人及為資料當事人的責任提供抵押的人士追收欠款；',
    wm4_12: '為符合根據下述適用於本公司或其任何分行或期望本公司或其任何分行遵從的有關披露及使用資料之責任、規定或安排：',
    wm4_12_1: '在香港特別行政區境內或境外對其具約束力或適用於其的任何法律；',
    wm4_12_2: '在香港特別行政區境內或境外並由任何法定、監管、政府、稅務、執法或其他機構，或由金融服務提供者之自律監管或行業的團體或組織所發出或提供之任何指引或指導；',
    wm4_12_3: '本公司或其任何分行因其金融、商業、營業或其他利益或活動處於或關連於相關本地或海外的法定、監管、政府、稅務、執法或其他機構或金融服務提供者之自律監管或行業團體或組織之司法管轄區而須承擔或獲施加與本地或海外之法定、監管、政府、稅務、執法或其他機構或金融服務提供者之自律監管或行業團體或組織之間的任何合約承諾或其他承諾；',
    wm4_13: '根據任何本集團計劃下就遵從洗黑錢、恐怖份子資金籌集或其他非法活動之批准或防止或偵測而作出本集團內及/或本公司不同部門之間的資料及信息分享及/或任何其他使用資料及信息的任何責任、規定、政策、程序、措施或安排；',
    wm4_14: '使本公司的實在或建議承讓人，或本公司對資料當事人的權利的參與人或附屬參與人評核意圖成為轉讓，參與或附屬參與的交易；',
    wm4_15: '與資料當事人或其他人士的資料作比較以進行信貸調查，資料核實或以其他方法產生或核實資料，不論有關比較是否為對該資料當事人採取不利的追討行動；',
    wm4_16: '作為維持資料當事人的信貸記錄或其他記錄，不論資料當事人與本公司是否存在任何關係，以作現在或將來參考用；',
    wm4_17: '就着資料當事人所欠本公司的一切債項向承保人投保與資料當事人相關連的人壽保險以保障一旦資料當事人身故後本公司的利益，而本公司將作為該保單的擁有人及唯一與最終受益人，「資料」在本條的釋義包括但不限於資料當事人的姓名、身份證明文件號碼、貸款戶口號碼、貸款額及不時改變的欠款額，該保單的擁有權、利益、權利及得益均屬本公司獨有而並不構成資料當事人對本公司欠款的扺押品；',
    wm4_18: '確認、驗證及認證資料當事人的身份；及',
    wm4_19: '進行貸款申請、服務及持續服務的審視；進行欺詐行為審視及調查；進行、籌備、促使內部及外部審計；對貸款申請、服務及持續服務行使信貸監控；處理針對本公司的申索及潛在申索；對本公司（包括本公司之不同部門）、本公司集團及承包商行使內部監控及資料管理；及',
    wm4_20: '一切與上述有聯繫、有附帶性或有關的用途。',
    wm5: '本公司會對其持有的資料當事人資料保密，但本公司可能會把該等資料提供及披露給下述各方作第7段列出的用途：',
    wm5_1: '任何代理人、審計員、承包人、或向本公司提供行政、一般支援、審計、資料管理、信貸監控、分析、產品審視、欺詐行為審視及調查、合規監管、電訊、電腦、付款或證券結算、電子身份認證服務或其他與本公司業務運作有關的服務的第三方服務供應商，不論其所在地；',
    wm5_2: '任何對本公司有保密責任的人士，包括本公司集團內已承諾保持該資料保密的公司及本公司不同部門之間；',
    wm5_3: '付款銀行向出票人提供已付款支票的副本（而其中可能載有關於收款人的資料）；',
    wm5_4: '任何付款到資料當事人賬戶的人士；',
    wm5_5: '任何從資料當事人收取付款的人士、其收款銀行及任何處理或辦理該付款的中介人士；',
    wm5_6: '信貸資料服務機構；而在資料當事人欠賬時，則可將該等資料提供給收數公司；',
    wm5_7: '本公司或其任何分行在根據對其本身或其任何分行具約束力或適用的法例規定下之責任或其他原因而必須向該人作出披露，或按照及為實施由任何法定、監管、政府、稅務、執法或其他機構或金融服務提供者之自律監管或行業團體或組織所提供或發出的指引或指導需預期向該人作出披露，或根據與本地或海外之法定、監管、政府、稅務、執法或其他機構或金融服務提供者之自律監管或行業團體或組織之間的任何合約承諾或其他承諾而向該人作出任何披露之任何人士，該等人士可能處於香港特別行政區境內或境外；',
    wm5_8: '本公司的任何實在或建議承讓人或就本公司對資料當事人的權利的參與人或附屬參與人或受讓人；及',
    wm5_9: '收集的資料向下列人士披露：',
    wm5_9_1: '本集團之任何成員；',
    wm5_9_2: '第三方金融機構、承保人、信用卡公司、證券、商品及投資服務供應商；',
    wm5_9_3: '第三方獎賞、年資獎勵、聯名合作及優惠計劃供應商；',
    wm5_9_4: '本公司及本集團之聯名合作夥伴(有關服務和產品的申請表上會提供聯名合作夥伴的名稱(視屬何情況而定))；',
    wm5_9_5: '慈善或非牟利組織；及',
    wm5_9_6: '就上述第7(i)段而獲本公司任用之第三方服務供應商(包括但不限於代寄郵件公司、電訊公司、電話促銷及直銷代理人、電話服務中心、數據處理公司、資訊科技公司及電子身份認證服務的承辦商)，不論其所在地。',
    bushi: {
      head: '本公司可能為上述第7段所列之目的不時將資料當事人的資料轉移往香港特別行政區境外的地區。',
      l1: '就以上第7(c)、7(o) 及7(r)段之目的，本公司可不時向信貸資料服務機構查閱及提取資料當事人的個人信貸資料，以檢討任何與信貸安排相關的下述事項：',
      l1_1: '資料當事人的身份；',
      l1_2: '增加信貸額；',
      l1_3: '縮減信貸額(包括取消信貸或降低信貸額)；或',
      l1_4: '與資料當事人制訂或推行債務安排計劃。'
    },
    hezhunguiding: {
      head: '本公司向信貸資料服務機構查閱資料當事人的個人信貸資料時，必須符合根據該條例核准和發出之《個人信貸資料實務守則》的規定。',
      l1: '就2011年4月1日或以後有關資料當事人按揭申請之資料(不論作為借款人、按揭人或擔保人及不論以資料當事人單名或與其他人士聯名方式)，本公司(以其自身及/或代理人身份)可向信貸資料服務機構提供下述關於資料當事人的資料(包括任何但不限於下述資料中不時更新之任何資料)：',
      l1_1: '全名；',
      l1_2: '就每宗按揭的身份(即作為借款人、按揭人或擔保人，以及不論以資料當事人單名或與其他人士聯名方式)；',
      l1_3: '身份證號碼或旅遊證件號碼；',
      l1_4: '出生日期；',
      l1_5: '通訊地址；',
      l1_6: '就每宗按揭的按揭賬戶號碼；',
      l1_7: '就每宗按揭的信貸種類；',
      l1_8: '就每宗按揭的按揭賬戶狀況(如生效、已結束、撇賬(因破產命令除外)、因破產命令的撇賬)；及',
      l1_9: '就每宗按揭的按揭賬戶結束日期(如適用)。'
    },
    zhijiecuxiao: {
      head: '信貸資料服務機構會使用上述由本公司提供的資料，統計資料當事人(分別以借款人、按揭人或擔保人身份，及不論其以單名或與其他人士聯名方式)不時與香港特別行政區內的信貸提供者持有之按揭宗數，於信貸資料庫內讓信貸提供者共用(惟受限於按條例核准及發出之《個人信貸資料實務守則》的規定)。',
      l1: '使用資料作直接促銷',
      liHead: '本公司擬使用資料當事人的資料作直接促銷及本公司須為此目的取得資料當事人同意(包括資料當事人不反對之表示)。因此，請注意以下：',
      l1_1: '本公司持有資料當事人的姓名、聯絡詳情、產品及服務投資組合信息、交易模式及行徑、財務背景及統計資料可不時被本公司用於直接促銷；',
      l1_2: '以下服務類別可作推廣：',
      l1_2_1: '信貸及相關服務和產品；',
      l1_2_2: '獎賞、年資獎勵或優惠計劃及相關服務和產品；',
      l1_2_3: '本公司的聯名合作夥伴提供之服務和產品(有關服務和產品的申請表上會提供聯名合作夥伴的名稱(視屬何情況而定) )；及',
      l1_2_4: '為慈善及或非牟利的目的之捐款及資助；',
      l1_3: '上述服務、產品及標的可由本公司及/或下述人士提供或(如涉及捐款及資助)募捐：',
      l1_3_1: '本集團之任何成員；',
      l1_3_2: '第三方金融機構、承保人、信用卡公司、證券、商品及投資服務供應商',
      l1_3_3: '第三方獎賞、年資獎勵、聯名合作及優惠計劃供應商；',
      l1_3_4: '本公司及本集團之聯名合作夥伴(有關服務和產品的申請表上會提供聯名合作夥伴的名稱(視屬何情況而定))；及',
      l1_3_5: '慈善或非牟利組織；',
      l1_4: '除本公司推廣上述服務、產品及標的外，本公司同時擬提供列明於上述第11(a)段之資料至上述第11(c)段的所有或其中任何人士，該等人士藉以用於推廣上述服務、產品及標的，而本公司須為此目的取得資料當事人同意(其中包括資料當事人不反對之表示)；',
      l1_5: '本公司如以上第11(d)段所述將資料提供予其他人士可能會獲得金錢或其他財產的回報。如本公司會因為提供資料予其他人士而獲得任何金錢或其他財產的回報，本公司會於以上第11(d)段所述徵求資料當事人同意或不反對時如是通知資料當事人。'
    },
    buyuanyi: {
      head: '若資料當事人不願意本公司使用或提供其資料予其他人士，藉以用於以上所述之直接促銷，資料當事人可通知本公司以行使其不同意此安排的權利。',
      l1: '根據條例中的條款及根據條例核准和發出的《個人信貸資料實務守則》，任何資料當事人有權：',
      l1_1: '查核本公司是否持有他的資料及查閱該等資料；',
      l1_2: '要求本公司改正任何有關他的不準確的資料；',
      l1_3: '查明本公司對於資料的政策及慣例和獲告知本公司持有的個人資料種類；',
      l1_4: '按要求獲告知哪些是會例行向信貸資料服務機構或收數公司披露的個人資料類別，及獲本公司提供進一步資料，以便向有關信貸資料服務機構或收數公司提出查閱和改正資料的要求；及',
      l1_5: '對於本公司向信貸資料服務機構提供的任何賬戶資料(為免生疑問，包括任何賬戶還款資料)，於悉數清償欠款以終止賬戶時，指示本公司要求有關信貸資料服務機構從其資料庫中刪除該等賬戶資料，惟是項指示必須於賬戶終止後5年內發出，且該賬戶在緊接賬戶終止之前5年內，並無超過60天的拖欠還款紀錄。賬戶還款資料包括最後一次到期的還款額、最後一次報告期間所作出的還款額(即緊接本公司向信貸資料服務機構提供最後一次賬戶資料前不超過31天的期間)、剩餘可用信貸額、或未償還款額及欠款資料(即過期欠款額及逾期還款日數、清還過期欠款的日期及全數清還拖欠超過60天的欠賬之日期(如有)。',
      l2: '在賬戶出現任何欠款的情況下，除非欠款金額在由出現拖欠日期起計60天屆滿前全數清還或撇賬(因破產命令除外)，信貸資料服務機構所可由全數清還欠款金額之日期起計5年保留賬戶還款資料(請見上述第12(e)段的定義)。',
      l3: '當資料當事人因被頒布破產命令而導致賬戶中的任何金額被撇賬，不論賬戶還款資料(請見上述第12(e)段的定義)是否顯示存有任何超過60天的欠款，信貸資料服務機構可由全數清還欠款金額之日期起計5年或由資料當事人提供證據通知信貸資料服務機構其已獲解除破產命令的日期起計5年保留賬戶還款資料(以較先出現者為準)。',
      l4: '根據條例的條款，本公司有權就處理任何查閱資料的要求收取合理費用。',
      l5: '任何關於查閱或改正資料，或索取關於資料政策及慣例或所持有的資料種類的要求，應向下列人士提出：'
    },
    ziliaobaozhang: {
      zhuren: '資料保障主任',
      gongsi: '利多喜有限公司',
      dizhi: '香港九龍尖沙咀廣東道28號力寶太陽廣場14樓1412室',
      dianhua: '電話：26380086 傳真：2638 0618',
      l1: '本公司在考慮任何信貸申請時或不時進行信貸審查時，會從信貸資料服務機構取得關於資料當事人的信貸報告。如資料當事人希望索閱該信貸報告，本公司會向其提供有關信貸資料服務機構的詳細聯絡資料。',
      l2: '如本通知中文版本與英文版本有任何歧義，則以英文版本為準。'
    }
  },
  cond: {
    title: '條款及細則',
    desc: '條款及條件',
    l1: '法律聲明',
    l1_1: '使用任何服務、瀏覽網站及使用手機應用程式前，請先閱讀以下條款及條件。',
    l1_2: '此文件是您與利多喜有限公司之間的具法律約束力的協議，其規定了使用網站和手機應用程式的條款及條件。假如您使用服務、瀏覽網站及使用手機應用程式或其任何部分，您將被視為已同意此等條款及條件。',
    l1_3: '假如您不接受此等條款及條件，請不要使用服務、瀏覽網站或使用手機應用程式或其任何部分。',
    l1_4: '假如您在此等條款及條件修訂後繼續使用服務、瀏覽網站及使用手機應用程式，您將被視為已同意經修訂的條款及條件。',
    l2: '定義',
    l2_1: '「本公司」指利多喜有限公司。',
    l2_2: '「服務」指瀏覽網站（https://www.rbmoney.hk）及使用本公司透過網站及/或手機應用程式提供的服務。',
    l2_3: '「網站」指本公司擁有及營運或為本公司營運的網站，包括（https://www.rbmoney.hk）（包括電腦及手機版本）及本公司可能不時營運及維持的其他網站。',
    l2_4: '「手機應用程式」的智能手機應用程式，包括蘋果iOS和谷歌Google Play版本，及本公司可能不時推出、營運及維持的其他版本。',
    l2_5: '「條款及條件」指此等條款及條件以及本公司的《私隱政策》和《個人資料收集聲明》。',
    l2_6: '「用戶」指通過使用電子設備包括但不限於電腦和手機（「電子設備」）瀏覽網站的訪客及使用手機應用程式的用戶；包括通過網站或手機應用程式與本公司訂立或確認訂立貸款協議的借款人。',
    l3: '產品及服務的提供',
    l3_1: '網站和手機應用程式提供一站式貸款服務，以及最新的物業價值信息，幫助用戶管理帳戶。用戶可在網上應用程式商店（Google Play和Apple Store）搜尋「快兔錢」手機應用程式作免費下載。如果發現任何程式有可疑，請不要下載或登錄，並請立即停止操作。',
    l3_2: '本公司僅將服務提供予本公司根據香港《放債人條例》可合法及適時地提供服務的司法管轄區，而網站和手機應用程式上的資料並不旨在提供予位於或居住在香港司法管轄區以外的地區的人士。瀏覽網站和手機應用程式的人士必須自行了解並遵守所有相關的法例及限制。',
    l3_3: '服務由本公司酌情提供，並受限於提供服務的個別條款及條件，本公司可隨時撤回或修改服務，恕不另行通知。',
    l3_4: '任何用戶透過網站及/或手機應用程式與本公司訂立任何貸款協議均不可推翻地被視為在香港境內訂立協議。本公司對於任何人適用特定服務的資格，具有最終決定權。',
    l4: '商標及版權',
    l4_1: '本公司擁有網站及/或手機應用程式的所有內容的版權，包括但不限於文字、圖形、連結和聲音的版權。未經本公司事先書面同意，此等內容不得被修改、轉載、以任何可被存取的方式儲存、傳輸（以任何形式或以任何方式）、複製、分發、用於創作衍生作品或以任何其他方式用於任何目的。',
    l4_2: '網站及/或手機應用程式所載的內容及信息，以及就網站及/或手機應用程式的使用而交付給用戶的內容及信息，均屬本公司和任何其他第三方（如適用）的財產。在網站及/或手機應用程式使用和顯示的商標、商品名稱和標誌（「商標」），包括本公司和其他第三方的註冊和未註冊商標。網站及/或手機應用程式中的任何內容均不應被解釋為授予使用當中顯示的任何商標的任何許可或權利。本公司保留網站及/或手機應用程式的所有專有權利。',
    l4_3: '除非受適用法律限制，否則就用戶使用網站及/或手機應用程式向本公司提交的任何信息或材料，用戶特此授予本公司此類信息或材料當中的版權和知識產權的免專利權費永久許可，讓本公司將之使用於其認為及暗示的任何目的，包括但不限於複製、傳輸、分發和發佈此類信息或材料。',
    l4_4: '用戶同意，除非用戶與本公司在雙方另行直接訂立的合約中另有約定，或法律另有要求，否則本公司無需對用戶使用網站及/或手機應用程式時向本公司提交的任何信息或材料，承擔任何保密義務或責任。',
    l5: '保安警告',
    l5_1: '避免透過在公共區域提供或無密碼設置的無線網絡（即Wi-Fi），瀏覽網站或使用手機應用程式。尤其是在處理財務交易時，請使用設有配置和可靠的互聯網連接。',
    l5_2: '並非正在使用任何無線網絡功能（如Wi-Fi、藍牙、近場通訊NFC）或支付程式時，請將其關閉。使用Wi-Fi時，選擇加密網絡，並刪除任何不必要的Wi-Fi連接設定。',
    l5_3: '請勿將電子設備連接到任何懷疑受病毒感染的電腦；同時，本公司建議您在電子設備安裝防毒軟件和設置防火牆。為避免任何潛在保安漏洞，請勿在已被破解的iPhone或已被取得系統最高管理權限的Android手機或任何廠方系統設定已被以非正式方式修改的設備，使用手機應用程式。用戶可以下載適當的安全應用程式或軟件。本公司有權拒絕用戶通過已被破解、已被取得系統最高管理權限或廠方系統設定已被以非正式方式編輯的電子設備瀏覽網站和手機應用程式。',
    l5_4: '本公司建議用戶開啓電子設備的屏幕自動鎖定功能。不要使用容易被猜到的個人資料、數字或字詞作為密碼，並避免使用與用戶曾使用或瀏覽的其他網絡服務相同的密碼。不要共用用戶名稱或密碼，也不要允許他人查閱或使用用戶名稱和密碼。勿讓電子設備無人看守。使用瀏覽器和手機應用程式後請將其關掉。為安全起見，網站及/或手機應用程式被閒置15分鐘後，其某些功能可能被關閉，或帳戶會被登出。',
    l5_5: '如果用戶懷疑用戶名稱或密碼已被披露給他人、被盜或有人進行了未經授權的交易，用戶有責任立即通知本公司，否則用戶仍須對所有交易承擔責任。',
    l5_6: '請定期下載並安裝手機應用程式及其他手機程式、操作系統和瀏覽器的更新和修復版本。',
    l5_7: '本公司藉着使用加密技術，盡力確保通過網站及手機應用程式傳輸的用戶數據和文件的安全性。為保護私隱和資產，用戶不應向任何人透露其帳號、用戶名稱和密碼，並應避免共用電子設備。此外，本公司提醒用戶不要通過載於電郵中的超連結瀏覽網站或手機應用程式。本公司絕不會通過電郵要求用戶提供帳號、密碼或任何個人資料。',
    l5_8: '使用網站及手機應用程式可能涉及風險，包括導致用戶的數據或電子設備（包括其設定）損失或損毀、在傳輸指示或訊息時出現攔截、干擾或變體、由於某些電子設備的自動密碼保存功能而導致的未經授權使用密碼。本公司對任何此類風險概不負責。',
    l6: '使用及披露個人資料',
    l6_1: '除非適用法律有所限制，否則用戶同意，由本公司透過網站及/或手機應用程式不時收集的任何及所有用戶個人資料/數據，可按照本公司的《私隱政策》及《個人資料收集聲明》(副本請瀏覽https://www.rbmoney.hk)，為了當中所載的目的使用及披露予當中所載的人士。',
    l6_2: '當用戶到訪網站時，本公司會作紀錄，以分析網站的到訪人數及一般使用模式。其中部份資料將透過「Cookies」形式收集。',
    l6_3: '「Cookies」檔案是由網站伺服器傳送至瀏覽器的小段資訊，這些資訊儲存於電腦硬碟中，使網站伺服器能於稍後再從瀏覽器內讀取，而當中並不會收集可識別用戶身份的資料。這有助網站保存某些有關用戶使用本網站的資料，好讓本公司能提供更有用的使用特色予用戶、將本網站的內容制定得更切合用戶的興趣，以及（如適用）根據用戶的使用模式向用戶提供宣傳內容及直接促銷。本公司可從「Cookies」檔案取得用戶如何使用本網站的資料。',
    l6_4: '「Cookies」檔案被設計成只可讓發出的網站讀取，但不能用作取得用戶的硬碟資料、電郵地址或收集用戶的敏感資料。',
    l6_5: '本公司亦與第三方機構合作，為網站的一般使用及活動進行研究，此等第三方機構包括谷歌、雅虎、Facebook及DoubleClick，他們使用包括「Cookies」、「Spotlight」及「Web Beacon (網絡信標)」等的監察技術來進行研究，透過此等技術，他們將所收集得到的資料 (i) 用以得到網站用戶的資料，包括其人口統計、行為及使用模式；(ii) 作出更準確的匯報；及 (iii) 協助提升本公司的市場推廣成效；他們將收集到的資料經過處理後與本公司分享，然而不論是谷歌、雅虎、Facebook或DoubleClick，進行上述研究的過程中均不會收集或與本公司分享任何可以識別用戶身份的資料。',
    l6_6: '大部份的瀏覽器都預設為接納使用「Cookies」檔案。若用戶認為有需要，用戶可將瀏覽器的「Cookies」設定停止或設置為當進行設定時通知用戶。倘若用戶將其網絡瀏覽器的「Cookies」檔案設定為停止運作，便未必能使用本公司於網上提供的財務產品及服務。倘若用戶接納使用「Cookies」檔案，即代表用戶同意其資料如上文般被收集、貯存、讀取及使用。',
    l6_7: '用戶如欲得到更多有關使用及收集「Cookies」檔案的資訊及選擇退出有關程序，可探訪以下網站：谷歌https://policies.google.com/technologies/cookies?hl=en-US、雅虎http://privacy.yahoo.com/privacy/us/pixels/details.html、Facebook https://www.facebook.com/legal/FB_Work_Cookies及 DoubleClick doubleclick.net。',
    l6_8: '如果用戶的電子設備有SD卡或其他儲存設備，本公司可能會使用這些儲存設備來儲存及存取加密了的應用數據，以便操作網站及/或手機應用程式。如用戶不允許本公司以上述方式使用信息，用戶可隨時更改電子設備的設定或刪除手機應用程式。在這種情況下，用戶將無法使用網站及/或手機應用程式。',
    l7: '連結到其他網站的超連結',
    l7_1: '在某些情況下，本公司會透過網站及/或手機應用程式提供連結到其他位置或網站的超連結。這些超連結會連結到由與本公司a.無任何聯繫或關係的第三方所發佈或營運的網站。在網站及/或手機應用程式中加入這些超連結，是為了促進用戶使用體驗，並僅供參考。本公司盡力選擇信譽良好的網站及信息來源，以方便用戶查閱相關信息。',
    l7_2: '本公司提供超連結，但不得因此而被視為同意、推薦、認可、保證或介紹任何第三方或該等第三方在其網站上提供的服務/產品，或與該等第三方和網站有任何形式的合作。',
    l7_3: '本公司並不以任何方式對超連結的內容負責。用戶在使用這些超連結時須自己承擔風險，本公司對由於用戶使用超連結而發生或遭受或與之有關的任何損害或損失，概不負責。本公司並非用戶與外部網站供應商之間訂立的任何合約安排的當事方。本公司並無調查、核實、監察或認可這些資源所提供的內容、準確性、所表達的意見及所提供的其他連結。',
    l7_4: '連結到其他網站的超連結，倘若包含可下載的軟件，其只為方便用戶而提供。本公司並不對用戶在下載軟件時遇到的任何困難或下載的任何後果承擔任何責任。使用從互聯網下載的任何軟件可能受特許協議所管限，而用戶如不遵守上述特許協議的條款，可能會導致侵犯相關軟件供應商的知識產權，對此本公司概不以任何方式負責。',
    l7_5: '當用戶點擊超連結及離開網站及/或手機應用程式時，用戶須遵守其將要瀏覽的其他網站的使用條款及私隱政策。',
    l7_6: '本公司可能會在網站及/或手機應用程式提供同一集團公司的網站的超連結，以方便用戶查閱。這些被連結之網站可能設有不同的使用條款及條件，因此您應在使用這些被連結之網站前，查詢及細閱適用的條款及條件。',
    l8: '從外部網站連結到本網站的超連結',
    l8_1: '用戶必須先徵求本公司的事先書面批准，才可以任何形式在第三方的網站建立連結到本公司網站的超連結。本公司具有絕對酌情決定是否批准建立超連結。在正常情況下，本公司只會批准清楚地顯示本公司的名稱或網站地址的超連結。除非在非常特殊的情況下，否則本公司不會批准第三方使用或展示我們的標誌、商品名稱和商標作為超連結，本公司具有絕對酌情決定為此徵收一定的費用。',
    l8_2: '對於從第三方網站連結到本公司網站的超連結的建立，本公司概不負責。如此建立的任何超連結不得構成本公司與該第三方網站的任何形式的合作或構成本公司認可該第三方網站。任何連結到本網站的超連結必須時刻是活躍而直接的超連結，且只能直接連結到本網站的首頁，而不得「框架化」或「深層連結」本網站或其內容。',
    l8_3: '本公司不會為用戶或任何第三方因為此等超連結而產生或遭受的任何損失或損害承擔任何責任。本公司保留權利隨時行使其絶對酌情權取消已授予的任何批准及/或要求刪除任何上述連結，而無需另行通知或作出補償。',
    l9: '不作保證',
    l9_1: '雖然本公司已極謹慎地準備網站及手機應用程式所載的信息和資料，但上述信息和資料是按「原樣」提供給用戶而本公司不作任何形式的明示或暗示的保證（包括但不限於可商售性、所有權、對某特定用途的適用性、不含電腦病毒、不侵犯第三方權益、兼容性、安全性、準確性和完整性），並可能在不作事先通知的情況下隨時被更改及撤回。',
    l9_2: '網站及手機應用程式所載的信息和資料可能由本公司相信為可靠的第三方來源提供或獲取。本公司並無核實也不保證上述信息和資料屬準確、完整或中肯或作出有關陳述。經濟、統計、財務及估值數據均以約數、概括或簡化形式呈示，並可能在不作通知的情況下隨時被更改。',
    l9_3: '用戶應該了解，網站及手機應用程式所載的關於費率、指數、價值和物業價值的任何信息會有時間上的延遲。本公司將盡力確保但不保證所提供信息的準確性及可靠性，且不會為因為任何不準確或遺漏而引起之損失或損害，承擔任何責任（不論是民事侵權行為責任、合約責任或其他責任）。',
    l9_4: '網站或手機應用程式所載的信息和資料不應被視為向任何人作出要約或徵求出售、認購或提供任何建議或意見。上述信息和資料並不旨在被用戶依賴以作出任何決定。用戶在作出任何財務決定或使用服務前，應諮詢自己的專業顧問。',
    l10: '法律責任的限度及免責條款',
    l10_1: '在法律允許的最大範圍內：',
    l10_1_1: '假如任何人直接或間接因為透過使用網站及/或手機應用程式的引用資料或超連結、書籤建議或以其他方式，使用或依賴與本公司無任何聯繫或關係的任何第三方所擁有及/或營運的任何網站所載的任何信息或資料（包括但不限於物業估值的網頁），而因此蒙受任何直接、間接、相應、專項或附帶的損害、損失或開支，無論是否因疏忽造成，並在所有上述情況下，無論本公司是否已獲告知發生損害、損失或開支的可能性，本公司也免除一切責任，本公司加入超連結並不意味着認可被連結之網站所載的任何信息或資料。',
    l10_1_2: '本公司概不承擔網站、手機應用程式及超連結所載的信息和資料的任何錯誤、遺漏或不準確，也不負責因為以下原因而導致的任何損失或損害：任何一方使用或依賴或無法使用上述信息和資料，或操作的任何錯誤、中斷、延遲或不完整的傳輸、傳輸過程中沒有加密或加密失敗（包括下載或上載數據）、傳輸未獲接收或只部分接收、傳輸中斷、傳輸延遲、線路或系統故障或電腦病毒，本公司也不以任何方式負責由此產生的直接、間接、專項、附帶或相應的損害。',
    l10_1_3: '本公司既不承擔也不接受任何（無論是直接還是間接的）損失或損害（包括專項、附帶或相應的損失或損害）的責任，包括但不限於以任何方式或因為使用網站及/或下載或安裝手機應用程式而帶來的任何病毒、木馬程式、蠕蟲、軟件炸彈或類似物品或程序所造成的網站及手機應用程式、其內容或關聯服務的任何缺陷、錯誤、瑕疵、故障、過失、未能提供或不準確性，在每一種情況下，上述事件發生的情況是超出本公司的合理控制範圍。',
    l10_1_4: '在任何情況下，即使本公司已被告知下述項目發生的可能性，本公司也不負責有關用戶瀏覽網站或使用手機應用程式的性能、系統、伺服器或連線故障、錯誤、遺漏、中斷，及安全漏洞、病毒、惡意代碼、亂碼或數據錯誤、操作或傳輸延遲、傳輸未獲接收或只部分接收或傳輸錯誤。',
    l10_2: '用戶須自行承擔使用服務的一切風險。本公司並不保證沒有病毒或其他具破壞性的屬性會傳輸到用戶的電子設備或用戶的電子設備不會受損或作出有關陳述。用戶須自行負責設立足夠的保護，並備份數據及/或設備，並採取合理和適當的預防措施以掃描病毒或其他具破壞性的屬性，以預防、保障及確保沒有病毒進入用戶的電子設備。本公司並不保證可能與服務共同使用或因為使用服務而可能使用的任何第三方軟件的準確性、功能或性能或作出有關陳述。',
    l10_3: '使用服務將同時須使用短信及/或電郵。使用服務、短信及/或電郵將涉及使用互聯網數據，用戶須自行承擔任何互聯網數據費用，包括所有跨網、漫遊及/或數據漫遊費用。持續讓全球定位系統在背景使用，會大幅減少電池壽命。',
    l11: '彌償',
    l11_1: '用戶特此同意作出彌償，並使本公司獲充分並有效地彌償本公司因為或直接或間接涉及以下事項而遭受或產生的任何訴訟、法律責任、費用、索賠、損失、損害、法律程序或開支（包括在完全彌償基準上的法律費用、訟費及開支）：',
    l11_1_1: '用戶瀏覽及/或使用網站及/或手機應用程式，及/或任何其他人士或個體使用服務或網站及/或手機應用程式，而該人士或個體能夠使用用戶的用戶名稱及/或密碼瀏覽服務或網站及/或手機應用程式；或',
    l11_1_2: '用戶或任何其他人士或個體違反或不遵守任何此等條款及條件，而該人士或個體能夠使用用戶的用戶名稱及/或密碼瀏覽服務或網站及/或手機應用程式。',
    l12: '修改',
    l12_1: '本公司保留其酌情決定權，在沒有事先警告或通知的情況下，更改網站及/或手機應用程式中包含的任何信息或資料，以及網站及/或手機應用程式的使用條款及條件。',
    l13: '終止',
    l13_1: '本公司可隨時在有因由或無因由的情況下，終止用戶瀏覽網站及/或手機應用程式，而不一定須給予用戶通知。',
    l14: '語言',
    l14_1: '此等條款及條件的中文版本僅供參考。如中英文版本有衝突或歧義，概以英文版本為準。',
    l15: '其他',
    l15_1: '用戶承認，此等條款及條件不得限制在網站及/或手機應用程式或透過網站及/或手機應用程式提供的任何特定產品和服務的個別條款及條件所列明的任何具體規定。',
    l15_2: '此等條款及條件是附加於本公司與用戶之間訂立的貸款協議中的條款。',
    l16: '司法管轄區及法律選擇',
    l16_1: '本公司在香港經營及維持服務。即使網站及手機應用程式可在香港以外地區瀏覽或使用，但透過網站及手機應用程式提供的服務及根據網站及手機應用程式訂立的所有合同，均屬在香港境內提供並訂立及須不可推翻地被視為在香港境內提供並訂立。本公司是在香港及被視為在香港營運網站及手機應用程式，而網站及手機應用程式的營運受香港法律管轄。',
    l16_2: '本公司並不對網站及手機應用程式所載的信息和資料是否適當或獲允許在香港境外的司法管轄區使用作出陳述。此等條款及條件受香港法律管轄，而用戶同意任何爭議須受香港法院的非專屬司法管轄權管轄。',
    l17: '小心偽冒員工推銷貸款產品',
    l17_1: '本公司不時接到投訴，指收到自稱是本公司的員工的人士的來電，推銷貸款服務。如果客戶及/或公眾不接受推銷，他們會惡言相向。本公司的政策是：',
    l17_1_1: '所有員工均接受過有關客戶服務和產品知識方面的專業培訓，並將有禮並誠懇地服務客戶和準客戶；',
    l17_1_2: '如果準客戶未具資格享用本公司的貸款服務或集團公司內的服務，員工只會在客戶同意的情況下將該等客戶轉介至與本公司有關、關聯或附屬的其他公司；',
    l17_1_3: '本公司保留對偽冒本公司或其附屬或關聯公司的員工的人士採取法律行動的權利；',
    l17_1_4: '所有推銷來電均直接由本公司的客戶服務主任、其分行或直銷部門的銷售代表或透過授權經銷商（統稱為「銷售代表」）作出，而來電也會顯示電話號碼；',
    l17_1_5: '所有銷售代表均應有自己的員工編號。他們樂意披露員工編號、分行名稱、部門名稱或授權經銷商的名稱及其聯繫電話號碼。',
    l17_2: '如果客戶或準客戶收到任何上述涉嫌偽冒來電或有任何懷疑，請致電本公司的客戶服務熱線：26380086。'
  },
  borrow: {
    title: '擬借款人須知'
  },
  news: {
    title: '最新消息',
    desc: '留意我們最新公佈'
  },
  about: {
    lht: '靈活兔',
    zyhs: '自由供款，自由話事',
    znlhzz: '助您靈活週轉',
    wxjslxzc: '我想减少利息支出',
    tksq: '特快申請',
    ybxx: '填寫個人資料',
    dkzl: '聯絡資料',
    gzzx: '職業資料',
    name: '姓名（香港身分證姓名）',
    phone: '電話號碼',
    email: '電郵',
    agree: '本人同意接收 Rabbit Money 的最新消息、優惠及推廣活動，並已詳細閱讀及明白下列的',
    grzlsytl: '個人資料私隱條例',
    applicating: '人正在申請中',
    fzrpzhm: '放債人牌照號碼',
    next: '下一步',
    pre: '上一步',
    submit: '遞交申請',
    why: '我們有各種類形貸款服務',
    desc: '為閣下提供最適切所需,只須簡單3個步驟',
    fourBox: {
      lht: {
        title: '靈活兔',
        desc: '自由供款，自由話事，助您靈活週轉'
      },
      srdk: {
        title: '私人貸款',
        desc: '定息定額，特快現金到手'
      },
      jyzh: {
        title: '結餘轉戶',
        desc: '大額清數，減輕壓力，共渡難關'
      },
      yalyjj: {
        title: '一按樓宇接揭',
        desc: '特高成數，文件簡易'
      },
      yzdk: {
        title: '業主貸款',
        desc: '專人處理業主貸款方案，貸款額特高！'
      },
      scwj: {
        title: '上傳文件',
        desc: '簡單上傳申請文件'
      }
    },
    purpose: [
      '交稅',
      '旅遊',
      '每月開支',
      '投資',
      '生意周轉',
      '醫療',
      '家具裝修',
      '結婚',
      '進修'
    ],
    area: [
      '新界',
      '九龍',
      '香港'
    ],
    region: {
      '新界': [
        '離島',
        '葵青',
        '北區',
        '西貢',
        '沙田',
        '大埔',
        '荃灣',
        '屯門',
        '元朗'
      ],
      '九龍': [
        '九龍城',
        '觀塘',
        '深水埗',
        '黃大仙',
        '油尖旺'
      ],
      '香港': [
        '中西區',
        '東區',
        '灣仔',
        '南區'
      ]
    },
    working: [
      '全職',
      '兼職',
      '待業',
      '自僱'
    ],
    situation: [
      '與父母同住',
      '自置（有按揭）',
      '公共房屋',
      '套房',
      '租用',
      '自置（無按揭）',
      '其它'
    ],
    occupation: [
      '會計業',
      '廣告業/市場推廣',
      '航空業',
      '美容行業/健身中心',
      '專業服務/顧問',
      '建造業',
      '設計業',
      '教育業',
      '機械工程業',
      '娛樂/傳媒',
      '金融業',
      '餐飲業',
      '政府部門',
      '酒店業/旅遊業',
      '通信業/資訊科技業',
      '法律服務',
      '人力資源顧問',
      '製造業',
      '醫療服務',
      '樓宇/物業代理',
      '零售業',
      '船務業/貿易',
      '運輸業/物流業',
      '公司擁有人/創辦人'
    ],
    contact: [
      '電話',
      'whatsapp'
    ],
    contactTime: [
      '盡快',
      '9am-12pm',
      '12pm-3pm',
      '3pm-6pm'
    ],
    messageRoute: [
      'Facebook/Instagram',
      '網上廣告',
      '關鍵字搜尋',
      '電視廣告',
      'Rabbit Money官網',
      'X Wallet手機應用程式',
      'YouTube',
      '宣傳單張',
      '手機短訊推廣',
      '電郵推廣',
      '戶外廣告',
      '親友推介'
    ],
    ge: '個',
    qtx: '請填寫',
    qxz: '請選擇',
    qsr: '請輸入',
    qsc: '請上傳',
    qlcxm: '請列出其姓名',
    qxxydjmbxl: '請詳細閱讀及明白下列',
    jzzl: '居住資料',
    szspwjhtgcy: '上載審批文件或跳過此頁',
    scndwjjkjkspsd: '上傳你的文件即可加快審批速度。',
    nyldx: '您與利多喜有限公司的關係',
    nsf: '您是否為利多喜有限公司、其附屬公司或並聯屬公司不論在香港境內或境外，或利多喜有限公司能對其行使控制的其他實體的董事／僱員／控權人／小股東控權人或上述人仕之親屬？',
    tzbgs: '如您與利多喜有限公司的關係有所改變或不再正確，請盡快以書面形式通知本公司',
    purposeLabel: '貸款目的',
    priceLabel: '貸款金額',
    cardNumberLabel: '香港身份證號碼',
    areaLabel: '區域',
    regionLabel: '地區',
    situationLabel: '住宅狀況',
    workingLabel: '現時工作狀況',
    occupationLabel: '職業',
    identityLabel: '香港身份證',
    addressLabel: '最近2個月內居住地址證明 （銀行月結單或公共機構發出有效的帳單並附上閣下姓名）',
    recordLabel: '最近2個月之入息證明（如個人或公司銀行戶口紀錄 / 僱主薪金證明信 / 稅單）',
    residenceLabel: '有關物業按揭的供款證明及結欠證明',
    contactLabel: '聯絡方法',
    contactTimeLabel: '聯絡時間',
    messageRouteLabel: '從什麼途徑得知Rabbit Money',
    tujingLabel: '途徑',
    brdsfrs: '本人同意授權貴公司向任何第三方人士索取有關本人的資料，包括但不限於向信貸資料服務機構/環聯資訊有限公司索取本人之信貸報告，用作是次及日後審核或覆核用途'
  },
  guanyuwomen: {
    title: '聯絡我們',
    desc: '聯絡我們',
    l1: '利多喜有限公司',
    l2: '香港九龍尖沙咀廣東道28號力寶太陽廣場14樓1412室',
    l3: '電話：26380086 '
  },
  result: {
    thanks: '己成功提交申請',
    gongxi: '多謝!我們已收到你的申請並處理中',
    jinkuai: '我們將會優先處理你的申請，客戶服務專員會盡快聯絡你，按你財務狀況，為你提供最合適你的貸款方案。如需進一步資料我們會盡快聯絡通知閣下，感謝!',
    duoxie: '多謝你選擇Rabbit Money'
  }
}
