import axios from 'axios'
const service = axios.create({
  // baseURL: 'http://8.134.57.119:5672/rabbit/web',
  baseURL: 'https://webserviceapi.rbmoney.hk/rabbit/web',
  timeout: 5000
  // withCredentials: true
})
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    return res
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)
export default service
