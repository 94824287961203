import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index.vue')
      },
      {
        path: '/promotion',
        name: 'Promotion',
        component: () => import('../views/promotion.vue')
      },
      {
        path: '/promotedetail',
        name: 'Detail',
        component: () => import('../views/promoteDetail.vue')
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../views/news.vue')
      },
      {
        path: '/newsdetail',
        name: 'Newsdetail',
        component: () => import('../views/newsDetail.vue')
      },
      {
        path: '/policy',
        name: 'Policy',
        component: () => import('../views/policy.vue')
      },
      {
        path: '/borrow',
        name: 'Borrow',
        component: () => import('../views/borrow.vue')
      },
      {
        path: '/apply',
        name: 'Apply',
        component: () => import('../views/apply.vue')
      },
      {
        path: '/tellus',
        name: 'Tellus',
        component: () => import('../views/tellus.vue')
      },
      {
        path: '/conditions',
        name: 'Conditions',
        component: () => import('../views/conditions.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/about.vue')
      },
      {
        path: '/result',
        name: 'Result',
        component: () => import('../views/result.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
