import request from '@/utils/axiosconfig'
// 轮播图
export function getBanner(someparmas) {
  return request({
    url: '/banner/list',
    method: 'get',
    params: someparmas
  })
}
// 关于我们
export function aboutUs() {
  return request({
    url: '/public/1',
    method: 'get'
  })
}
// 个人案例
export function getCase(someparmas) {
  return request({
    url: '/case/list',
    method: 'get',
    params: someparmas
  })
}
// 重要通知
export function getNotice() {
  return request({
    url: '/notice/list',
    method: 'get'
  })
}
// 最新优惠
export function getPromotion(someparmas) {
  return request({
    url: '/preferential/list',
    method: 'get',
    params: someparmas
  })
}
// 最新优惠详情
export function getPromotionDetail(someparmas) {
  return request({
    url: `/preferential/${someparmas}`,
    method: 'get'
  })
}
// 最新消息
export function getNews(someparmas) {
  return request({
    url: '/news/list',
    method: 'get'
  })
}
// 最新消息详情
export function getNewsDetail(someparmas) {
  return request({
    url: `/news/${someparmas}`,
    method: 'get'
  })
}
// 申请贷款
export function submitApply(someparmas) {
  return request({
    url: '/personal/apply',
    method: 'post',
    data: someparmas
  })
}
// 上傳
export function uploadRequest(someparmas) {
  return request({
    url: 'upload',
    method: 'post',
    data: someparmas,
    processData: false,
    contentType: false,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
    }
  })
}
