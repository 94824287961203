import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import './assets/styles/index.scss'
import i18n from '@/i18n'
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
Vue.use(Antd).use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.prototype.$lan = localStorage.getItem('local') || 'tc'
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
